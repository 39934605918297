import React, { forwardRef, useImperativeHandle, useState } from "react";
import {
    Button,
    FormCheck,
    Modal,
    Tab,
    Table,
    Tabs
} from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import Select from "react-select";
import InputMask from "react-input-mask";

import ModalLoading from "../../components/ModalLoading/ModalLoading";
import DFormText from "../../components/DFormText/DFormText.js";
import { states } from "../../../enum/enums";

import { v4 as uuidv4 } from "uuid";
import { cnpj } from "cpf-cnpj-validator";
import correios from "../../../services/CorreiosService.js";

import api from "../../../services/api.js";

const CustomerForm = forwardRef((props, ref) => {
    const { onClose } = props;
    const [modalBox, setModalBox] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [loadingCustomerForm, setLoadingCustomerForm] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState("Gravando Cliente...");

    const [customerId, setCustomerId] = useState(undefined);
    const [documentId, setDocumentId] = useState("");
    const [name, setName] = useState("");
    const [shortName, setShortName] = useState("");
    const [ie, setIe] = useState("");
    const [im, setIm] = useState("");

    const [idEditAddress, setIdEditAddress] = useState(undefined);
    const [addressList, setAddressList] = useState([]);
    const [showAddressForm, setShowAddressForm] = useState(false);
    const [addressPlace, setAddressPlace] = useState("");
    const [zipCode, setZipCode] = useState("");
    const [address, setAddress] = useState("");
    const [addressNumber, setAddressNumber] = useState("");
    const [complement, setComplement] = useState("");
    const [district, setDistrict] = useState("");
    const [city, setCity] = useState("");
    const [addressState, setAddressState] = useState(null);

    const [disableAddress, setDisableAddress] = useState(false);
    const [disableDistrict, setDisableDistrict] = useState(false);
    const [disableCity, setDisableCity] = useState(false);
    const [disableAddressState, setDisableAddressState] = useState(false);

    const [idEditContact, setIdEditContact] = useState(undefined);
    const [contactList, setContactList] = useState([]);
    const [showContactForm, setShowContactForm] = useState(false);
    const [contactName, setContactName] = useState("");
    const [contactPhone, setContactPhone] = useState("");
    const [contactEmail, setContactEmail] = useState("");

    const [active, setActive] = useState(true);

    const [invalidName, setInvalidName] = useState(false);
    const [invalidShortName, setInvalidShortName] = useState(false);
    const [invalidDocumentId, setInvalidDocumentId] = useState(false);
    const [invalidDocumentIdText, setInvalidDocumentIdText] = useState("Informe um CNPJ válido");
    const [invalidAddress, setInvalidAddress] = useState(false);
    const [invalidContact, setInvalidContact] = useState(false);

    const [invalidAddressPlace, setInvalidAddressPlace] = useState(false);
    const [invalidAddressZipCode, setInvalidAddressZipCode] = useState(false);
    const [invalidAddressName, setInvalidAddressName] = useState(false);
    const [invalidAddressNumber, setInvalidAddressNumber] = useState(false);
    const [invalidAddressCity, setInvalidAddressCity] = useState(false);
    const [invalidAddressState, setInvalidAddressState] = useState(false);

    const [invalidContactName, setInvalidContactName] = useState(false);
    const [invalidContactEmail, setInvalidContactEmail] = useState(false);
    const [invalidContactInfo, setInvalidContactInfo] = useState(false);

    const [errorMessage, setErrorMessage] = useState("Ocorreu um problema ao salvar o registro");

    useImperativeHandle(ref, () => ({
        async openModal(mode, customerInfo = {}) {
            setModalBox(true);
            setIsUpdate(false);

            if (mode === "update") {
                setIsUpdate(true);

                setCustomerId(customerInfo._id);
                setActive(customerInfo.status);
                setDocumentId(customerInfo.document_id);
                setName(customerInfo.name);
                setShortName(customerInfo.short_name);
                setIe(customerInfo.ie);
                setIm(customerInfo.im);

                const addresses = customerInfo.addresses.map(addressItem => {
                    return { ...addressItem, innerId: uuidv4() };
                });
                setAddressList(addresses);

                const contacts = customerInfo.contacts.map(contactItem => {
                    return { ...contactItem, innerId: uuidv4() };
                });
                setContactList(contacts);
            }
        },
    }));

    async function handleSaveCustomer(e) {
        e.preventDefault();

        setLoadingMessage("Gravando Cliente...");
        setLoadingCustomerForm(true);

        setInvalidName(false);
        setInvalidShortName(false);
        setInvalidAddress(false);
        setInvalidContact(false);

        let hasError = false;

        const documentUnformatted = documentId.replace(/[.]/g, "").replace("/", "").replace("-", "");

        if (!invalidDocumentId) {
            if (documentUnformatted.length === 0) {
                setInvalidDocumentIdText("Informe o CNPJ");
                setInvalidDocumentId(true);
                hasError = true;
            } else {
                if (!cnpj.isValid(documentUnformatted)) {
                    setInvalidDocumentIdText("Informe um CNPJ válido");
                    setInvalidDocumentId(true);
                    hasError = true;
                }
            }
        }

        if (!name) {
            setInvalidName(true);
            hasError = true;
        }

        if (!shortName) {
            setInvalidShortName(true);
            hasError = true;
        }

        if (addressList.length === 0) {
            setInvalidAddress(true);
            hasError = true;
        }

        if (contactList.length === 0) {
            setInvalidContact(true);
            hasError = true;
        }

        if (!hasError) {
            const customer = {
                status: active,
                document_id: documentUnformatted,
                name,
                short_name: shortName,
                ie,
                im,
                addresses: [...addressList],
                contacts: [...contactList]
            };

            if (!isUpdate) {
                try {
                    await api.post("/customers", customer)
                        .then(response => {
                            if (response.status === 201) {
                                setLoadingCustomerForm(false);
                                notifySuccess();
                                resetFields();
                                resetAddressFields();
                                resetContactFields();
                                onClose();
                                setModalBox(false);
                            }
                        })
                } catch (err) {
                    if (err.data.error === "ALREADY_EXISTS") {
                        setErrorMessage("Cliente já cadastrado!");
                    } else {
                        setErrorMessage("Ocorreu um problema ao salvar o registro");
                    }
                    notifyError();
                }
            } else {
                try {
                    await api.put(`/customers/${customerId}`, customer)
                        .then(response => {
                            if (response.status === 200) {
                                setLoadingCustomerForm(false);
                                notifySuccess();
                                resetFields();
                                resetAddressFields();
                                resetContactFields();
                                onClose();
                                setModalBox(false);
                            }
                        })
                } catch (err) {
                    notifyError();
                }
            }
        }

        setLoadingCustomerForm(false);
    }

    function notifySuccess() {
        toast.success(`✔️ Cliente ${(isUpdate ? "alterado" : "incluído")} com sucesso!`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    function notifyError() {
        toast.error(`❌ ${errorMessage}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    function handleCancel() {
        resetFields();
        resetAddressFields();
        setModalBox(false);
    }

    function resetFields() {
        setCustomerId(undefined);
        setDocumentId("");
        setName("");
        setShortName("");
        setIe("");
        setIm("");

        setAddressList([]);
        setShowAddressForm(false);

        setContactList([]);
        setShowContactForm(false);

        setActive(true);

        setInvalidName(false);
        setInvalidShortName(false);
        setInvalidDocumentId(false);
        setInvalidDocumentIdText("Informe um CNPJ válido");
        setInvalidAddress(false);
        setInvalidContact(false);

        setErrorMessage("Ocorreu um problema ao salvar o registro");
    }

    function handleAddAddress(e) {
        e.preventDefault();

        setInvalidAddressPlace(false);
        setInvalidAddressZipCode(false);
        setInvalidAddressName(false);
        setInvalidAddressNumber(false);
        setInvalidAddressCity(false);
        setInvalidAddressState(false);

        let hasError = false;

        if (!addressPlace) {
            setInvalidAddressPlace(true);
            hasError = true;
        }

        if (!zipCode) {
            setInvalidAddressZipCode(true);
            hasError = true;
        }

        if (!address) {
            setInvalidAddressName(true);
            hasError = true;
        }

        if (!addressNumber) {
            setInvalidAddressNumber(true);
            hasError = true;
        }

        if (!city) {
            setInvalidAddressCity(true);
            hasError = true;
        }

        if (!addressState) {
            setInvalidAddressState(true);
            hasError = true;
        }

        if (!hasError) {
            let newAddressList = [...addressList];

            if (!idEditAddress) {
                newAddressList.push({
                    innerId: uuidv4(),
                    place: addressPlace,
                    zip_code: zipCode,
                    address: address,
                    address_number: addressNumber,
                    complement: complement,
                    district: district,
                    city: city,
                    state: addressState.value
                });
            } else {
                const addressToEditIndex = newAddressList.findIndex(x => x.innerId === idEditAddress);
                if (addressToEditIndex >= 0) {
                    newAddressList[addressToEditIndex].place = addressPlace;
                    newAddressList[addressToEditIndex].zip_code = zipCode;
                    newAddressList[addressToEditIndex].address = address;
                    newAddressList[addressToEditIndex].address_number = addressNumber;
                    newAddressList[addressToEditIndex].complement = complement;
                    newAddressList[addressToEditIndex].district = district;
                    newAddressList[addressToEditIndex].city = city;
                    newAddressList[addressToEditIndex].state = addressState.value;
                }

                setIdEditAddress(undefined);
            }

            setAddressList(newAddressList);

            resetAddressFields();
            setInvalidAddress(false);
        }
    }

    function handleCancelAddress() {
        resetAddressFields();
        setShowAddressForm(!showAddressForm);
    }

    function handleEditAddress(id) {
        const addressToEdit = [...addressList].find(x => x.innerId === id);

        if (addressToEdit) {
            setShowAddressForm(true);

            setIdEditAddress(id);
            setAddressPlace(addressToEdit.place);
            setZipCode(addressToEdit.zip_code);
            setAddress(addressToEdit.address);
            setAddressNumber(addressToEdit.address_number);
            setComplement(addressToEdit.complement);
            setDistrict(addressToEdit.district);
            setCity(addressToEdit.city);
            setAddressState(states.find(x => x.value === addressToEdit.state));
        }
    }

    function handleDeleteAddress(id) {
        const newAddressList = [...addressList].filter(x => x.innerId !== id);
        setAddressList(newAddressList);
    }

    function resetAddressFields() {
        setAddressPlace("");
        setZipCode("");
        setAddress("");
        setAddressNumber("");
        setComplement("");
        setDistrict("");
        setCity("");
        setAddressState(null);

        setDisableAddress(false);
        setDisableDistrict(false);
        setDisableCity(false);
        setDisableAddressState(false);
    }

    function handleAddContact(e) {
        e.preventDefault();

        setInvalidContactName(false);
        setInvalidContactInfo(false);

        let hasError = false;

        if (!contactName) {
            setInvalidContactName(true);
            hasError = true;
        }

        if (!contactEmail && !contactPhone) {
            setInvalidContactInfo(true);
            hasError = true;
        } else if (contactEmail) {
            let validateEmail = /\S+@\S+\.\S+/;
            if (!validateEmail.test(contactEmail)) {
                setInvalidContactEmail(true);
                hasError = true;
            }
        }

        if (!hasError) {
            let newContactList = [...contactList];

            if (!idEditContact) {
                newContactList.push({
                    innerId: uuidv4(),
                    name: contactName,
                    email: contactEmail,
                    phone: contactPhone
                });
            } else {
                const contactToEditIndex = newContactList.findIndex(x => x.innerId === idEditContact);
                if (contactToEditIndex >= 0) {
                    newContactList[contactToEditIndex].name = contactName;
                    newContactList[contactToEditIndex].email = contactEmail;
                    newContactList[contactToEditIndex].phone = contactPhone;
                }

                setIdEditContact(undefined);
            }

            setContactList(newContactList);

            resetContactFields();
            setInvalidContact(false);
        }
    }

    function handleCancelContact() {
        resetContactFields();
        setShowContactForm(!showContactForm);
    }

    function handleEditContact(id) {
        const contactToEdit = [...contactList].find(x => x.innerId === id);

        if (contactToEdit) {
            setShowContactForm(true);

            setIdEditContact(id);
            setContactName(contactToEdit.name);
            setContactEmail(contactToEdit.email);
            setContactPhone(contactToEdit.phone);
        }
    }

    function handleDeleteContact(id) {
        const newContactList = [...contactList].filter(x => x.innerId !== id);
        setContactList(newContactList);
    }

    function resetContactFields() {
        setContactName("");
        setContactPhone("");
        setContactEmail("");

        setInvalidContactEmail(false);
    }

    async function maskDocumentField() {
        setInvalidDocumentId(false);

        const documentUnformatted = documentId.replace(/[.]/g, "").replace("/", "").replace("-", "");

        if (documentUnformatted.length > 0) {
            if (!cnpj.isValid(documentUnformatted)) {
                setInvalidDocumentIdText("Informe um CNPJ válido");
                setInvalidDocumentId(true);
            } else {
                if (!isUpdate) {
                    await api.get(`/customers/document/${documentUnformatted}`)
                        .then(response => {
                            if (response.status === 200) {
                                setInvalidDocumentId(true);
                                setInvalidDocumentIdText("CNPJ já cadastrado");
                            }
                        });
                }
            }
        } else {
            setInvalidDocumentIdText("Informe o CNPJ");
            setInvalidDocumentId(true);
        }
    }

    async function maskZipCodeField() {
        setLoadingMessage("Buscando Endereço...");
        setLoadingCustomerForm(true);

        const zipCodeUnformatted = zipCode.replace(".", "").replace("-", "");

        setAddress("");
        setAddressNumber("");
        setComplement("");
        setDistrict("");
        setCity("");
        setAddressState("");

        setDisableAddress(false);
        setDisableDistrict(false);
        setDisableCity(false);
        setDisableAddressState(false);

        if (zipCodeUnformatted.trim().length === 8) {
            await correios.get(`/ws/${zipCodeUnformatted}/json`)
                .then(response => {
                    if (response.status === 200) {
                        if (!response.data.erro) {
                            const cepResponse = response.data;

                            if (cepResponse.logradouro) {
                                setAddress(cepResponse.logradouro);
                                setDisableAddress(true);
                            }

                            if (cepResponse.bairro) {
                                setDistrict(cepResponse.bairro);
                                setDisableDistrict(true);
                            }

                            if (cepResponse.localidade) {
                                setCity(cepResponse.localidade);
                                setDisableCity(true);
                            }

                            if (cepResponse.uf) {
                                const stateInfo = states.find(x => x.value === cepResponse.uf);
                                setAddressState(stateInfo);
                                setDisableAddressState(true);
                            }
                        }
                    }
                })
        }

        setLoadingCustomerForm(false);
    }

    return (
        <>
            <Modal size="xl" onHide={setModalBox} show={modalBox}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h3
                            className="modal-title"
                            id="exampleModalLabel"
                        >
                            {(!isUpdate ? "Novo " : "Alterar ")}Cliente
                        </h3>
                        <button
                            type="button"
                            className="btn-close"
                            onClick={() => setModalBox(false)}
                        ></button>
                    </div>
                    <div className="modal-body">
                        <div className="basic-form">
                            <div className="row" hidden={!isUpdate}>
                                <div className="form-group mb-3 col-md-6">
                                    <FormCheck
                                        type="switch"
                                        id="active"
                                        label="Ativo"
                                        defaultChecked={active}
                                        onChange={() => setActive(!active)}
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="form-group mb-12 col-md-12">
                                    <Tabs
                                        defaultActiveKey="personal"
                                        id="fill-tab-example"
                                        className="mb-3 basic-list-group"
                                        fill
                                    >
                                        <Tab eventKey="personal" title="Dados Cadastrais">
                                            <div className="row">
                                                <div className="form-group mb-3 col-md-3">
                                                    <label>CNPJ</label>
                                                    <InputMask
                                                        mask="99.999.999/9999-99"
                                                        maskChar=" "
                                                        value={documentId}
                                                        onChange={(event) => {
                                                            setDocumentId(event?.target.value);
                                                        }}
                                                        onBlur={() => { maskDocumentField() }}
                                                        alwaysShowMask={true}
                                                        disabled={isUpdate}
                                                    >
                                                        {(inputProps) => <input {...inputProps} autoFocus type="text" className={`form-control ${(invalidDocumentId ? "is-invalid" : "")}`} disabled={isUpdate} />}
                                                    </InputMask>
                                                    <DFormText hidden={!invalidDocumentId} color="danger">{invalidDocumentIdText}</DFormText>
                                                </div>
                                                <div className="form-group mb-3 col-md-9">
                                                    <label>Razão Social</label>
                                                    <input
                                                        type="text"
                                                        className={`form-control ${(invalidName ? "is-invalid" : "")}`}
                                                        value={name}
                                                        onChange={e => setName(e.target.value.toUpperCase())}
                                                        disabled={isUpdate}
                                                    />
                                                    <DFormText hidden={!invalidName} color="danger">Informe a razão social</DFormText>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="form-group mb-3 col-md-6">
                                                    <label>Nome Fantasia</label>
                                                    <input
                                                        type="text"
                                                        className={`form-control ${(invalidShortName ? "is-invalid" : "")}`}
                                                        value={shortName}
                                                        onChange={e => setShortName(e.target.value.toUpperCase())}
                                                        disabled={isUpdate}
                                                    />
                                                    <DFormText hidden={!invalidShortName} color="danger">Informe o nome fantasia</DFormText>
                                                </div>
                                                <div className="form-group mb-3 col-md-3">
                                                    <label>Inscrição Estadual</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={ie}
                                                        onChange={e => setIe(e.target.value)}
                                                    />
                                                </div>
                                                <div className="form-group mb-3 col-md-3">
                                                    <label>Inscrição Municipal</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={im}
                                                        onChange={e => setIm(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </Tab>

                                        <Tab eventKey="addresses" title="Endereços">
                                            <div className="row" hidden={showAddressForm}>
                                                <div className="mb-3 d-flex justify-content-end">
                                                    <button
                                                        className="btn btn-outline-primary ms-1"
                                                        onClick={() => setShowAddressForm(!showAddressForm)}
                                                        title="Adicionar Endereço"
                                                    >
                                                        Adicionar Endereço
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="row" hidden={!showAddressForm}>
                                                <div className="row">
                                                    <div className="form-group mb-3 col-md-3">
                                                        <label>Local</label>
                                                        <input
                                                            autoFocus
                                                            id="addressPlace"
                                                            type="text"
                                                            className={`form-control ${(invalidAddressPlace ? "is-invalid" : "")}`}
                                                            value={addressPlace}
                                                            onChange={e => setAddressPlace(e.target.value)}
                                                        />
                                                        <DFormText hidden={!invalidAddressPlace} color="danger">Informe o local para identificação</DFormText>
                                                    </div>
                                                    <div className="form-group mb-3 col-md-2">
                                                        <label>CEP</label>
                                                        <InputMask
                                                            mask="99.999-999"
                                                            maskChar=" "
                                                            value={zipCode}
                                                            onChange={(event) => {
                                                                if (event.target.value.length !== 10) return false;
                                                                setZipCode(event?.target.value);
                                                            }}
                                                            onBlur={() => { maskZipCodeField() }}
                                                            alwaysShowMask={true}
                                                        >
                                                            {(inputProps) => <input {...inputProps} type="text" className={`form-control ${(invalidAddressZipCode ? "is-invalid" : "")}`} />}
                                                        </InputMask>
                                                        <DFormText hidden={!invalidAddressZipCode} color="danger">Informe o CEP</DFormText>
                                                    </div>
                                                    <div className="form-group mb-3 col-md-7">
                                                        <label>Logradouro</label>
                                                        <input
                                                            id="addressStreet"
                                                            type="text"
                                                            className={`form-control ${(invalidAddressName ? "is-invalid" : "")}`}
                                                            value={address}
                                                            onChange={e => setAddress(e.target.value)}
                                                            disabled={disableAddress}
                                                        />
                                                        <DFormText hidden={!invalidAddressName} color="danger">Informe o endereço</DFormText>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="form-group mb-3 col-md-2">
                                                        <label>Número</label>
                                                        <input
                                                            id="addressNumberInput"
                                                            type="text"
                                                            className={`form-control ${(invalidAddressNumber ? "is-invalid" : "")}`}
                                                            value={addressNumber}
                                                            onChange={e => setAddressNumber(e.target.value)}
                                                        />
                                                        <DFormText hidden={!invalidAddressNumber} color="danger">Informe o número</DFormText>
                                                    </div>
                                                    <div className="form-group mb-3 col-md-5">
                                                        <label>Complemento</label>
                                                        <input
                                                            id="addressComplement"
                                                            type="text"
                                                            className="form-control"
                                                            value={complement}
                                                            onChange={e => setComplement(e.target.value)}
                                                        />
                                                    </div>
                                                    <div className="form-group mb-3 col-md-5">
                                                        <label>Bairro</label>
                                                        <input
                                                            id="addressDistrict"
                                                            type="text"
                                                            className="form-control"
                                                            value={district}
                                                            onChange={e => setDistrict(e.target.value)}
                                                            disabled={disableDistrict}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="form-group mb-3 col-md-6">
                                                        <label>Cidade</label>
                                                        <input
                                                            id="addressCity"
                                                            type="text"
                                                            className={`form-control ${(invalidAddressCity ? "is-invalid" : "")}`}
                                                            value={city}
                                                            onChange={e => setCity(e.target.value)}
                                                            disabled={disableCity}
                                                        />
                                                        <DFormText hidden={!invalidAddressCity} color="danger">Informe a cidade</DFormText>
                                                    </div>
                                                    <div className="form-group mb-3 col-md-6">
                                                        <label>UF</label>
                                                        <Select
                                                            id="addressState"
                                                            className={`${(invalidAddressState ? "form-control is-invalid" : "")}`}
                                                            defaultValue={addressState}
                                                            value={addressState}
                                                            onChange={e => setAddressState(e)}
                                                            options={states}
                                                            styles={{
                                                                control: (baseStyles, state) => ({
                                                                    ...baseStyles,
                                                                    height: 45,
                                                                    backgroundColor: state.isDisabled ? "#dde0e3" : ""
                                                                }),
                                                            }}
                                                            placeholder="Selecione..."
                                                            isDisabled={disableAddressState}
                                                        />
                                                        <DFormText hidden={!invalidAddressState} color="danger">Selecione o estado</DFormText>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="mb-3 d-flex justify-content-end">
                                                        <button
                                                            className="btn btn-outline-danger ms-1"
                                                            onClick={handleCancelAddress}
                                                            title="Cancelar"
                                                        >
                                                            Cancelar
                                                        </button>
                                                        <button
                                                            className="btn btn-outline-success ms-1"
                                                            onClick={handleAddAddress}
                                                            title="Adicionar Endereço"
                                                        >
                                                            Confirmar
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <h5 hidden={!invalidAddress} className="text-danger">Deve ser informado pelo menos um endereço</h5>
                                            </div>
                                            <div className="row">
                                                <Table responsive>
                                                    <thead>
                                                        <tr>
                                                            <th>
                                                                <strong>Local</strong>
                                                            </th>
                                                            <th>
                                                                <strong>Endereço</strong>
                                                            </th>
                                                            <th>
                                                                <strong>Cidade</strong>
                                                            </th>
                                                            <th>
                                                                <strong>Estado</strong>
                                                            </th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            addressList.map(addressItem => {
                                                                return (
                                                                    <tr key={addressItem.innerId}>
                                                                        <td>{addressItem.place}</td>
                                                                        <td>{addressItem.address}, {addressItem.address_number}</td>
                                                                        <td>{addressItem.city}</td>
                                                                        <td>{addressItem.state}</td>
                                                                        <td>
                                                                            <div className="d-flex">
                                                                                <button
                                                                                    className="btn btn-primary shadow sharp ms-1"
                                                                                    title="Editar endereço"
                                                                                    onClick={() => handleEditAddress(addressItem.innerId)}
                                                                                >
                                                                                    <i className="fas fa-pencil-alt"></i>
                                                                                </button>
                                                                                <button
                                                                                    className="btn btn-danger shadow sharp ms-1"
                                                                                    title="Excluir endereço"
                                                                                    onClick={() => handleDeleteAddress(addressItem.innerId)}
                                                                                >
                                                                                    <i className="fa fa-trash"></i>
                                                                                </button>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </Tab>
                                        <Tab eventKey="contacts" title="Contatos">
                                            <div className="row" hidden={showContactForm}>
                                                <div className="mb-3 d-flex justify-content-end">
                                                    <button
                                                        className="btn btn-outline-primary ms-1"
                                                        onClick={() => setShowContactForm(!showContactForm)}
                                                        title="Adicionar Contato"
                                                    >
                                                        Adicionar Contato
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="row" hidden={!showContactForm}>
                                                <div className="row">
                                                    <div className="form-group mb-3 col-md-4">
                                                        <label>Nome</label>
                                                        <input
                                                            id="contactName"
                                                            type="text"
                                                            className={`form-control ${(invalidContactName ? "is-invalid" : "")}`}
                                                            value={contactName}
                                                            onChange={e => setContactName(e.target.value)}
                                                        />
                                                        <DFormText hidden={!invalidContactName} color="danger">Informe o nome do contato</DFormText>
                                                    </div>
                                                    <div className="form-group mb-3 col-md-5">
                                                        <label>Email</label>
                                                        <input
                                                            id="contactEmail"
                                                            type="text"
                                                            className={`form-control ${((invalidContactInfo || invalidContactEmail) ? "is-invalid" : "")}`}
                                                            value={contactEmail}
                                                            onChange={e => setContactEmail(e.target.value)}
                                                        />
                                                        <DFormText hidden={!invalidContactInfo} color="danger">Informe o e-mail ou o telefone</DFormText>
                                                        <DFormText hidden={!invalidContactEmail} color="danger">Informe um e-mail válido</DFormText>
                                                    </div>
                                                    <div className="form-group mb-3 col-md-3">
                                                        <label>Telefone</label>
                                                        <InputMask
                                                            mask="(99) 99999-9999"
                                                            maskChar=" "
                                                            value={contactPhone}
                                                            onChange={(event) => {
                                                                setContactPhone(event?.target.value);
                                                            }}
                                                            alwaysShowMask={true}
                                                        >
                                                            {(inputProps) => <input {...inputProps} type="text" className={`form-control ${(invalidContactInfo ? "is-invalid" : "")}`} />}
                                                        </InputMask>
                                                        <DFormText hidden={!invalidContactInfo} color="danger">Informe o e-mail ou o telefone</DFormText>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="mb-3 d-flex justify-content-end">
                                                        <button
                                                            className="btn btn-outline-danger ms-1"
                                                            onClick={handleCancelContact}
                                                            title="Cancelar"
                                                        >
                                                            Cancelar
                                                        </button>
                                                        <button
                                                            className="btn btn-outline-success ms-1"
                                                            onClick={handleAddContact}
                                                            title="Adicionar Endereço"
                                                        >
                                                            Confirmar
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <h5 hidden={!invalidContact} className="text-danger">Deve ser informado pelo menos um contato</h5>
                                            </div>
                                            <div className="row">
                                                <Table responsive>
                                                    <thead>
                                                        <tr>
                                                            <th>
                                                                <strong>Nome</strong>
                                                            </th>
                                                            <th>
                                                                <strong>Email</strong>
                                                            </th>
                                                            <th>
                                                                <strong>Telefone</strong>
                                                            </th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            contactList.map(contactItem => {
                                                                return (
                                                                    <tr key={contactItem.innerId}>
                                                                        <td>{contactItem.name}</td>
                                                                        <td>{contactItem.email}</td>
                                                                        <td>{contactItem.phone}</td>
                                                                        <td>
                                                                            <div className="d-flex">
                                                                                <button
                                                                                    className="btn btn-primary shadow sharp ms-1"
                                                                                    title="Editar contato"
                                                                                    onClick={() => handleEditContact(contactItem.innerId)}
                                                                                >
                                                                                    <i className="fas fa-pencil-alt"></i>
                                                                                </button>
                                                                                <button
                                                                                    className="btn btn-danger shadow sharp ms-1"
                                                                                    title="Excluir contato"
                                                                                    onClick={() => handleDeleteContact(contactItem.innerId)}
                                                                                >
                                                                                    <i className="fa fa-trash"></i>
                                                                                </button>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </Tab>

                                    </Tabs>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <Button
                            variant="outline-danger btn-rounded"
                            onClick={handleCancel}
                        >
                            Fechar
                        </Button>
                        <Button
                            variant="success btn-rounded"
                            onClick={handleSaveCustomer}
                        >
                            Salvar
                        </Button>
                    </div>
                </div>
            </Modal>

            <ToastContainer />

            <ModalLoading
                visible={loadingCustomerForm}
                message={loadingMessage}
                onClose={setLoadingCustomerForm}
            />
        </>
    );
});

export default CustomerForm;
