import axios from "axios";

// eslint-disable-next-line no-undef
const API_URL = process.env.NODE_ENV === "production" ? "https://api.cfnk.com.br" : "http://localhost:3333";

const api = axios.create({
    baseURL: API_URL,
    headers: {
        Accept: "application/json"
    }
});

api.interceptors.request.use(config => {
    let accesToken = undefined;

    if (localStorage.getItem("xDetRem")) {
        accesToken = localStorage.getItem("xDetTk");
    } else {
        accesToken = sessionStorage.getItem("xDetTk");
    }

    if (accesToken) {
        config.headers.common = { Authorization: `Bearer ${accesToken}` };
    }

    return config;
},
    error => {
        Promise.reject(error.response || error.message);
    })

api.interceptors.response.use(response => {
    return response;
},
    error => {
        let originalRequest = error.config;
        let refreshToken = sessionStorage.getItem("xDetTkr");
        if (localStorage.getItem("xDetRem")) {
            refreshToken = localStorage.getItem("xDetTkr");
        }

        if (refreshToken && error.response.status === 403 && !originalRequest._retry) {
            originalRequest._retry = true;

            return axios.post(`${API_URL}/refresh-token`, {
                token: refreshToken
            }).then(res => {
                if (res.status === 200) {
                    if (localStorage.getItem("xDetRem")) {
                        localStorage.setItem("xDetTk", res.data.token);
                        localStorage.setItem("xDetTkr", res.data.refresh_token);
                    } else {
                        sessionStorage.setItem("xDetTk", res.data.token);
                        sessionStorage.setItem("xDetTkr", res.data.refresh_token);
                    }

                    originalRequest.headers['Authorization'] = `Bearer ${res.data.token}`;

                    return axios(originalRequest);
                }
            }).catch(() => {
                sessionStorage.clear();
                localStorage.clear();
                window.location.assign("/login");
            });
        }

        return Promise.reject(error.response || error.message);
    }
)

export default api;