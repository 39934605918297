import React, { forwardRef, useImperativeHandle, useState } from "react";
import {
    Button,
    Modal,
    Tab,
    Table,
    Tabs
} from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import Select from "react-select";
import CurrencyInput from "react-currency-input-field";
import dayjs from "dayjs";
import { v4 as uuidv4 } from "uuid";

import ModalLoading from "../../components/ModalLoading/ModalLoading";
import DFormText from "../../components/DFormText/DFormText.js";

import { calculateWorkTime } from "../../../services/utils.js";
import { projectsTypes, projectsPriceType, projectsStatus, workOrderStatusOptions } from "../../../enum/enums";
import api from "../../../services/api";

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ptBR from "date-fns/locale/pt-BR";
import DPagination from "../../components/DPagination/DPagination.js";
registerLocale("ptBR", ptBR);

const ProjectForm = forwardRef((props, ref) => {
    const { onClose } = props;
    const [modalBox, setModalBox] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [loadingProjectForm, setLoadingProjectForm] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState("Gravando Projeto...");

    const [projectId, setProjectId] = useState("");
    const [customersList, setCustomersList] = useState([]);
    const [customer, setCustomer] = useState(null);
    const [projectName, setProjectName] = useState("");
    const [status, setStatus] = useState("Não iniciado");
    const [type, setType] = useState(null);
    const [totalHours, setTotalHours] = useState("");
    const [price, setPrice] = useState(0);
    const [priceType, setPriceType] = useState(null);
    const [initialDate, setInitialDate] = useState("");
    const [estimatedDate, setEstimatedDate] = useState("");
    const [finalDate, setFinalDate] = useState("");
    const [observations, setObservations] = useState("");

    const [tasksList, setTasksList] = useState([]);
    const [editTaskId, setEditTaskId] = useState(undefined);
    const [showTaskForm, setShowTaskForm] = useState(false);
    const [taskName, setTaskName] = useState("");
    const [taskHours, setTaskHours] = useState("");
    const [taskInitialDate, setTaskInitialDate] = useState("");
    const [taskEstimatedDate, setTaskEstimatedDate] = useState("");
    const [taskFinalDate, setTaskFinalDate] = useState("");

    const [workOrdersList, setWorkOrdersList] = useState([]);
    const [totalWorkOrdersPages, setTotalWorkOrdersPages] = useState(1);
    const [currentWorkOrdersPage, setCurrentWorkOrdersPage] = useState(1);

    const [invalidCustomer, setInvalidCustomer] = useState(false);
    const [invalidProjectName, setInvalidProjectName] = useState(false);
    const [invalidType, setInvalidType] = useState(false);
    const [invalidPrice, setInvalidPrice] = useState(false);
    const [invalidPriceType, setInvalidPriceType] = useState(false);
    const [invalidInitialDate, setInvalidInitialDate] = useState(false);

    const [invalidTaskName, setInvalidTaskName] = useState(false);
    const [invalidTaskInitialDate, setInvalidTaskInitialDate] = useState(false);

    const [errorMessage, setErrorMessage] = useState("Ocorreu um problema ao salvar o registro");

    useImperativeHandle(ref, () => ({
        async openModal(mode, projectInfo = {}) {
            setModalBox(true);
            setIsUpdate(false);

            getCustomers();

            if (mode === "update") {
                setIsUpdate(true);

                loadProjectInfo(projectInfo);
            }
        },
    }));

    async function getCustomers() {
        await api.get("/customers?offset=1&limit=100")
            .then(response => {
                if (response.status === 200) {
                    const customersReturned = response.data.customers.map(customerInfo => {
                        return {
                            value: customerInfo._id,
                            label: customerInfo.name
                        }
                    })

                    setCustomersList(customersReturned);
                }
            })
    }

    async function loadProjectInfo(projectInfo) {
        setLoadingMessage("Carregando Projeto...")
        setLoadingProjectForm(true);

        setProjectId(projectInfo._id);
        setCustomer({ value: projectInfo.customer_id, label: projectInfo.customer_name });
        setProjectName(projectInfo.description);
        setStatus(projectInfo.statusDescription);
        setType(projectsTypes.find(x => x.value === projectInfo.project_type));
        setTotalHours(projectInfo.estimated_hours);
        setPrice(projectInfo.price);
        setPriceType(projectsPriceType.find(x => x.value === projectInfo.price_type));
        setInitialDate(dayjs(projectInfo.start_date).toDate());
        setEstimatedDate(projectInfo.estimated_date ? dayjs(projectInfo.estimated_date).toDate() : "");
        setFinalDate(projectInfo.end_date ? dayjs(projectInfo.end_date).toDate() : "");
        setObservations(projectInfo.observation);

        const taskListReturned = projectInfo.tasks.map(taskItem => {
            const statusInfo = projectsStatus.find(x => x.value === taskItem.status);

            return {
                innerId: uuidv4(),
                status: taskItem.status,
                statusDescription: statusInfo?.label ?? "pending",
                statusColor: statusInfo?.color ?? "text-warning",
                task: taskItem.task,
                estimated_hours: taskItem.estimated_hours,
                start_date: dayjs(taskItem.start_date).toDate(),
                estimated_date: taskItem.estimated_date ? dayjs(taskItem.estimated_date).toDate() : "",
                end_date: taskItem.end_date ? dayjs(taskItem.end_date).toDate() : ""
            }
        });
        setTasksList(taskListReturned);

        getWorkOrders(1, projectInfo._id);

        setLoadingProjectForm(false);
    }

    async function getWorkOrders(page = currentWorkOrdersPage, project_id = projectId) {
        await api.get(`/workorders?offset=${page}&limit=10&project=${project_id}`)
            .then(response => {
                if (response.status === 200) {
                    const workOrdersReturned = response.data.workOrders.map(workOrderItem => {
                        const workOrderStatusInfo = workOrderStatusOptions.find(x => x.value === workOrderItem.status);

                        return {
                            id: workOrderItem._id,
                            innerId: uuidv4(),
                            status: workOrderItem.status,
                            status_description: workOrderStatusInfo.label,
                            status_color: workOrderStatusInfo.color,
                            schedule_date: dayjs(workOrderItem.schedule_date).toDate(),
                            user_name: workOrderItem.user_name,
                            task_description: workOrderItem.task_description,
                            total_time: calculateWorkTime(workOrderItem.start_time, workOrderItem.end_time, workOrderItem.trip_time, workOrderItem.discount_time)
                        }
                    });

                    setWorkOrdersList(workOrdersReturned);

                    const pages = Math.ceil(response.data.total / 10);
                    setTotalWorkOrdersPages(Math.max(pages, 1));
                }
            })
    }

    async function handleSaveProject(e) {
        e.preventDefault();

        setLoadingMessage("Gravando Projeto...")
        setLoadingProjectForm(true);

        setInvalidCustomer(false);
        setInvalidProjectName(false);
        setInvalidType(false);
        setInvalidPrice(false);
        setInvalidPriceType(false);
        setInvalidInitialDate(false);

        let hasError = false;

        if (!customer) {
            setInvalidCustomer(true);
            hasError = true;
        }

        if (!projectName) {
            setInvalidProjectName(true);
            hasError = true;
        }

        if (!type) {
            setInvalidType(true);
            hasError = true;
        }

        if (price <= 0) {
            setInvalidPrice(true);
            hasError = true;
        }

        if (!priceType) {
            setInvalidPriceType(true);
            hasError = true;
        }

        if (!initialDate) {
            setInvalidInitialDate(true);
            hasError = true;
        }

        if (!hasError) {
            let projectInfo = {
                customer_id: customer.value,
                customer_name: customer.label,
                description: projectName,
                project_type: type.value,
                estimated_hours: totalHours,
                price: price,
                price_type: priceType.value,
                start_date: dayjs(initialDate).toDate(),
                estimated_date: estimatedDate ? dayjs(estimatedDate).toDate() : "",
                end_date: finalDate ? dayjs(finalDate).toDate() : "",
                observation: observations,
                tasks: tasksList.map(taskItem => {
                    return {
                        status: taskItem.status,
                        task: taskItem.task,
                        estimated_hours: taskItem.estimated_hours,
                        start_date: taskItem.start_date,
                        estimated_date: taskItem.estimated_date,
                        end_date: taskItem.end_date
                    }
                })
            };

            if (!isUpdate) {
                projectInfo.status = "pending";

                try {
                    await api.post("/projects", projectInfo)
                        .then(response => {
                            if (response.status === 201) {
                                setLoadingProjectForm(false);
                                notifySuccess();
                                resetFields();
                                resetTaskFields();
                                onClose();
                                setModalBox(false);
                            }
                        })
                } catch (err) {
                    notifyError();
                }
            } else {
                try {
                    await api.put(`/projects/${projectId}`, projectInfo)
                        .then(response => {
                            if (response.status === 200) {
                                setLoadingProjectForm(false);
                                notifySuccess();
                                resetFields();
                                resetTaskFields();
                                onClose();
                                setModalBox(false);
                            }
                        })
                } catch (err) {
                    notifyError();
                }
            }
        }

        setLoadingProjectForm(false);
    }

    function notifySuccess() {
        toast.success(`✔️ Projeto ${(isUpdate ? "alterado" : "incluído")} com sucesso!`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    function notifyError() {
        toast.error(`❌ ${errorMessage}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    function resetFields() {
        setProjectId("");
        setCustomersList([]);
        setCustomer(null);
        setProjectName("");
        setStatus("Não iniciado");
        setType(null);
        setTotalHours("");
        setPrice(0);
        setPriceType(null);
        setInitialDate("");
        setEstimatedDate("");
        setFinalDate("");
        setObservations("");

        setTasksList([]);
        setWorkOrdersList([]);

        setInvalidCustomer(false);
        setInvalidProjectName(false);
        setInvalidType(false);
        setInvalidPrice(false);
        setInvalidPriceType(false);
        setInvalidInitialDate(false);
    }

    function handleAddTask() {
        setInvalidTaskName(false);
        setInvalidTaskInitialDate(false);

        let hasError = false;

        if (!taskName) {
            setInvalidTaskName(true);
            hasError = true;
        }

        if (!taskInitialDate) {
            setInvalidTaskInitialDate(true);
            hasError = true;
        }

        if (!hasError) {
            let newTaskList = [...tasksList];

            if (!editTaskId) {
                newTaskList.push({
                    innerId: uuidv4(),
                    status: "pending",
                    statusDescription: "Não Iniciado",
                    statusColor: "text-warning",
                    task: taskName,
                    estimated_hours: taskHours,
                    start_date: taskInitialDate,
                    estimated_date: taskEstimatedDate,
                    end_date: taskFinalDate
                });
            } else {
                const indexToEdit = newTaskList.findIndex(x => x.innerId === editTaskId);

                if (indexToEdit >= 0) {
                    newTaskList[indexToEdit].task = taskName;
                    newTaskList[indexToEdit].estimated_hours = taskHours;
                    newTaskList[indexToEdit].start_date = taskInitialDate;
                    newTaskList[indexToEdit].estimated_date = taskEstimatedDate;
                    newTaskList[indexToEdit].end_date = taskFinalDate;
                }
            }

            setTasksList(newTaskList);
            resetTaskFields();
        }
    }

    function handleCancelTask() {
        resetTaskFields();
        setShowTaskForm(!showTaskForm);
    }

    function handleEditTask(id) {
        setEditTaskId(id);

        const taskToEdit = tasksList.find(x => x.innerId === id);
        if (taskToEdit) {
            setTaskName(taskToEdit.task);
            setTaskHours(taskToEdit.estimated_hours);
            setTaskInitialDate(taskToEdit.start_date);
            setTaskEstimatedDate(taskToEdit.estimated_date);
            setTaskFinalDate(taskToEdit.end_date);

            setShowTaskForm(true);
        }
    }

    function handleDeleteTask(id) {
        const newTaskList = [...tasksList].filter(x => x.innerId !== id);
        setTasksList(newTaskList);
    }

    function resetTaskFields() {
        setEditTaskId(undefined);
        setTaskName("");
        setTaskHours("");
        setTaskInitialDate("");
        setTaskEstimatedDate("");
        setTaskFinalDate("");

        setInvalidTaskName(false);
        setInvalidTaskInitialDate(false);
    }

    function setActiveWorkOrdersPage(page) {
        setCurrentWorkOrdersPage(page);
        getWorkOrders(page);
    }

    return (
        <>
            <Modal size="xl" fullscreen onHide={setModalBox} show={modalBox}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h3
                            className="modal-title"
                            id="exampleModalLabel"
                        >
                            {(!isUpdate ? "Novo " : "Alterar ")}Projeto
                        </h3>
                        <button
                            type="button"
                            className="btn-close"
                            onClick={() => setModalBox(false)}
                        ></button>
                    </div>
                    <div className="modal-body">
                        <div className="basic-form">
                            <div className="row">
                                <div className="form-group mb-12 col-md-12">
                                    <Tabs
                                        defaultActiveKey="personal"
                                        id="fill-tab-example"
                                        className="mb-3 basic-list-group"
                                        fill
                                    >
                                        <Tab eventKey="personal" title="Dados do Projeto">
                                            <div className="row">
                                                <div className="form-group mb-3 col-md-2">
                                                    <label>Status</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={status}
                                                        onChange={e => setStatus(e.target.value)}
                                                        disabled
                                                    />
                                                </div>
                                                <div className="form-group mb-3 col-md-4">
                                                    <label>Cliente</label>
                                                    <Select
                                                        id="customer"
                                                        autoFocus
                                                        isClearable
                                                        className={`${(invalidCustomer ? "form-control is-invalid" : "")}`}
                                                        defaultValue={customer}
                                                        value={customer}
                                                        onChange={e => setCustomer(e)}
                                                        options={customersList}
                                                        styles={{
                                                            control: (baseStyles, state) => ({
                                                                ...baseStyles,
                                                                height: 45,
                                                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                                                            }),
                                                        }}
                                                        isDisabled={isUpdate}
                                                        placeholder="Selecione..."
                                                    />
                                                    <DFormText hidden={!invalidCustomer} color="danger">Informe o cliente</DFormText>
                                                </div>
                                                <div className="form-group mb-3 col-md-6">
                                                    <label>Projeto</label>
                                                    <input
                                                        type="text"
                                                        className={`form-control ${(invalidProjectName ? "is-invalid" : "")}`}
                                                        value={projectName}
                                                        onChange={e => setProjectName(e.target.value.toUpperCase())}
                                                        disabled={isUpdate}
                                                    />
                                                    <DFormText hidden={!invalidProjectName} color="danger">Informe o nome do projeto</DFormText>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="form-group mb-3 col-md-3">
                                                    <label>Tipo do Projeto</label>
                                                    <Select
                                                        id="type"
                                                        isClearable
                                                        className={`${(invalidType ? "form-control is-invalid" : "")}`}
                                                        defaultValue={type}
                                                        value={type}
                                                        onChange={e => setType(e)}
                                                        options={projectsTypes}
                                                        styles={{
                                                            control: (baseStyles, state) => ({
                                                                ...baseStyles,
                                                                height: 45,
                                                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                                                            }),
                                                        }}
                                                        placeholder="Selecione..."
                                                    />
                                                    <DFormText hidden={!invalidType} color="danger">Informe o tipo do projeto</DFormText>
                                                </div>
                                                <div className="form-group mb-3 col-md-3">
                                                    <label>Horas Orçadas</label>
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        value={totalHours}
                                                        onChange={e => setTotalHours(e.target.value)}
                                                        min="0"
                                                    />
                                                </div>
                                                <div className="form-group mb-3 col-md-3">
                                                    <label>Valor</label>
                                                    <CurrencyInput
                                                        className={`form-control ${(invalidPrice ? "is-invalid" : "")} text-end`}
                                                        id="input-price"
                                                        defaultValue={price}
                                                        decimalsLimit={2}
                                                        onValueChange={(value) => setPrice(value)}
                                                        intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
                                                    />
                                                    <DFormText hidden={!invalidPrice} color="danger">Informe o valor</DFormText>
                                                </div>
                                                <div className="form-group mb-3 col-md-3">
                                                    <label>Tipo do Valor</label>
                                                    <Select
                                                        id="priceType"
                                                        isClearable
                                                        className={`${(invalidPriceType ? "form-control is-invalid" : "")}`}
                                                        defaultValue={priceType}
                                                        value={priceType}
                                                        onChange={e => setPriceType(e)}
                                                        options={projectsPriceType}
                                                        styles={{
                                                            control: (baseStyles, state) => ({
                                                                ...baseStyles,
                                                                height: 45,
                                                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                                                            }),
                                                        }}
                                                        placeholder="Selecione..."
                                                    />
                                                    <DFormText hidden={!invalidPriceType} color="danger">Informe o tipo do valor do projeto</DFormText>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="form-group mb-3 col-md-3">
                                                    <label>Data de Início</label>
                                                    <br />
                                                    <DatePicker
                                                        selected={initialDate}
                                                        onChange={(date) => setInitialDate(date)}
                                                        locale="ptBR"
                                                        dateFormat="P"
                                                        className={`form-control ${(invalidInitialDate ? " is-invalid" : "")}`}
                                                    />
                                                    <br />
                                                    <DFormText hidden={!invalidInitialDate} color="danger">Informe a data de início</DFormText>
                                                </div>
                                                <div className="form-group mb-3 col-md-3">
                                                    <label>Data Final Prevista</label>
                                                    <br />
                                                    <DatePicker
                                                        selected={estimatedDate}
                                                        onChange={(date) => setEstimatedDate(date)}
                                                        locale="ptBR"
                                                        dateFormat="P"
                                                        className="form-control"
                                                    />
                                                </div>
                                                <div className="form-group mb-3 col-md-3">
                                                    <label>Data Real de Entrega</label>
                                                    <br />
                                                    <DatePicker
                                                        selected={finalDate}
                                                        onChange={(date) => setFinalDate(date)}
                                                        locale="ptBR"
                                                        dateFormat="P"
                                                        className="form-control"
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="form-group mb-3 col-md-12">
                                                    <label>Observações</label>
                                                    <textarea
                                                        type="text"
                                                        className="form-control"
                                                        value={observations}
                                                        onChange={e => setObservations(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </Tab>

                                        <Tab eventKey="tasks" title="Tarefas">
                                            <div className="row" hidden={showTaskForm}>
                                                <div className="mb-3 d-flex justify-content-end">
                                                    <button
                                                        className="btn btn-outline-primary ms-1"
                                                        onClick={() => setShowTaskForm(!showTaskForm)}
                                                        title="Adicionar Tarefa"
                                                    >
                                                        Adicionar Tarefa
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="row" hidden={!showTaskForm}>
                                                <div className="row">
                                                    <div className="form-group mb-3 col-md-10">
                                                        <label>Tarefa</label>
                                                        <input
                                                            id="taskName"
                                                            type="text"
                                                            className={`form-control ${(invalidTaskName ? " is-invalid" : "")}`}
                                                            value={taskName}
                                                            onChange={e => setTaskName(e.target.value)}
                                                        />
                                                        <DFormText hidden={!invalidTaskName} color="danger">Informe a descrição da tarefa</DFormText>
                                                    </div>
                                                    <div className="form-group mb-3 col-md-2">
                                                        <label>Horas Previstas</label>
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            value={taskHours}
                                                            onChange={e => setTaskHours(e.target.value)}
                                                            min="0"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="form-group mb-3 col-md-3">
                                                        <label>Data de Início</label>
                                                        <br />
                                                        <DatePicker
                                                            selected={taskInitialDate}
                                                            onChange={(date) => setTaskInitialDate(date)}
                                                            locale="ptBR"
                                                            dateFormat="P"
                                                            className={`form-control ${(invalidTaskInitialDate ? " is-invalid" : "")}`}
                                                        />
                                                        <br />
                                                        <DFormText hidden={!invalidTaskInitialDate} color="danger">Informe a data de início</DFormText>
                                                    </div>
                                                    <div className="form-group mb-3 col-md-3">
                                                        <label>Data Final Prevista</label>
                                                        <br />
                                                        <DatePicker
                                                            selected={taskEstimatedDate}
                                                            onChange={(date) => setTaskEstimatedDate(date)}
                                                            locale="ptBR"
                                                            dateFormat="P"
                                                            className="form-control"
                                                        />
                                                    </div>
                                                    <div className="form-group mb-3 col-md-3">
                                                        <label>Data Real de Entrega</label>
                                                        <br />
                                                        <DatePicker
                                                            selected={taskFinalDate}
                                                            onChange={(date) => setTaskFinalDate(date)}
                                                            locale="ptBR"
                                                            dateFormat="P"
                                                            className="form-control"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="mb-3 d-flex justify-content-end">
                                                        <button
                                                            className="btn btn-outline-danger ms-1"
                                                            onClick={handleCancelTask}
                                                            title="Cancelar"
                                                        >
                                                            Cancelar
                                                        </button>
                                                        <button
                                                            className="btn btn-outline-success ms-1"
                                                            onClick={handleAddTask}
                                                            title="Adicionar Tarefa"
                                                        >
                                                            Confirmar
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <Table responsive>
                                                    <thead>
                                                        <tr key="headTask">
                                                            <th>
                                                                <strong>Tarefa</strong>
                                                            </th>
                                                            <th>
                                                                <strong>Horas Previstas</strong>
                                                            </th>
                                                            <th>
                                                                <strong>Data Início</strong>
                                                            </th>
                                                            <th>
                                                                <strong>Data Prevista</strong>
                                                            </th>
                                                            <th>
                                                                <strong>Data Entrega</strong>
                                                            </th>
                                                            <th>
                                                                <strong>Status</strong>
                                                            </th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            tasksList.map(taskItem => {
                                                                return (
                                                                    <tr key={taskItem.innerId}>
                                                                        <td>{taskItem.task}</td>
                                                                        <td className="text-center">{taskItem.estimated_hours}</td>
                                                                        <td>{dayjs(taskItem.start_date).format("DD/MM/YYYY")}</td>
                                                                        <td>{taskItem.estimated_date ? dayjs(taskItem.estimated_date).format("DD/MM/YYYY") : ""}</td>
                                                                        <td>{taskItem.end_date ? dayjs(taskItem.end_date).format("DD/MM/YYYY") : ""}</td>
                                                                        <td>
                                                                            <div className="d-flex align-items-center">
                                                                                <i className={`fa fa-circle ${taskItem.statusColor} me-1`}></i>{" "}
                                                                                {taskItem.statusDescription}
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <div className="d-flex">
                                                                                <button
                                                                                    className="btn btn-primary shadow sharp ms-1"
                                                                                    title="Editar tarefa"
                                                                                    onClick={() => handleEditTask(taskItem.innerId)}
                                                                                >
                                                                                    <i className="fas fa-pencil-alt"></i>
                                                                                </button>
                                                                                {
                                                                                    taskItem.status === "pending" ? (
                                                                                        <button
                                                                                            className="btn btn-danger shadow sharp ms-1"
                                                                                            title="Excluir tarefa"
                                                                                            onClick={() => handleDeleteTask(taskItem.innerId)}
                                                                                        >
                                                                                            <i className="fa fa-trash"></i>
                                                                                        </button>
                                                                                    ) : ""
                                                                                }
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </Tab>
                                        <Tab eventKey="workorders" title="OS's">
                                            <div className="row">
                                                <Table responsive>
                                                    <thead>
                                                        <tr key="headWork">
                                                            <th>
                                                                <strong>Data</strong>
                                                            </th>
                                                            <th>
                                                                <strong>Consultor</strong>
                                                            </th>
                                                            <th>
                                                                <strong>Horas</strong>
                                                            </th>
                                                            <th>
                                                                <strong>Status</strong>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            workOrdersList.map(workOrderItem => {
                                                                return (
                                                                    <tr key={workOrderItem.innerId} style={{ cursor: "pointer" }}>
                                                                        <td>{dayjs(workOrderItem.schedule_date).format("DD/MM/YYYY")}</td>
                                                                        <td>{workOrderItem.user_name}</td>
                                                                        <td>{workOrderItem.total_time}</td>
                                                                        <td>
                                                                            <div className="d-flex align-items-center">
                                                                                <i className={`fa fa-circle ${workOrderItem.status_color} me-1`}></i>{" "}
                                                                                {workOrderItem.status_description}
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </Table>

                                                <DPagination
                                                    activePage={currentWorkOrdersPage}
                                                    pages={totalWorkOrdersPages}
                                                    onActivePageChange={(i) => setActiveWorkOrdersPage(i)}
                                                />
                                            </div>
                                        </Tab>

                                    </Tabs>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <Button
                            variant="outline-danger btn-rounded"
                            onClick={() => setModalBox(false)}
                        >
                            Fechar
                        </Button>
                        <Button
                            variant="success btn-rounded"
                            onClick={handleSaveProject}
                        >
                            Salvar
                        </Button>
                    </div>
                </div>
            </Modal>

            <ToastContainer />

            <ModalLoading
                visible={loadingProjectForm}
                message={loadingMessage}
                onClose={setLoadingProjectForm}
            />
        </>
    );
});

export default ProjectForm;
