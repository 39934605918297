import React, { forwardRef, useImperativeHandle, useState } from "react";
import {
    Button,
    FormCheck,
    Modal,
} from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import Select from "react-select";

import ModalLoading from "../../components/ModalLoading/ModalLoading";
import DFormText from "../../components/DFormText/DFormText.js";
import { profiles } from "../../../enum/enums";

import api from "../../../services/api.js";

const UserForm = forwardRef((props, ref) => {
    const { onClose } = props;
    const [modalBox, setModalBox] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [loadingUserForm, setLoadingUserForm] = useState(false);

    const [userId, setUserId] = useState("");
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [profile, setProfile] = useState(profiles.find(x => x.value === "consultant"));
    const [password, setPassword] = useState("");

    const [active, setActive] = useState(true);

    const [invalidName, setInvalidName] = useState(false);
    const [invalidEmail, setInvalidEmail] = useState(false);
    const [invalidEmailMessage, setInvalidEmailMessage] = useState("Informe o e-mail do usuário");
    const [invalidPassword, setInvalidPassword] = useState(false);

    const [errorMessage, setErrorMessage] = useState("Ocorreu um problema ao salvar o registro");

    useImperativeHandle(ref, () => ({
        async openModal(mode, userInfo = {}) {
            setModalBox(true);
            setIsUpdate(false);

            if (mode === "update") {
                setIsUpdate(true);

                setUserId(userInfo._id);
                setActive(userInfo.status);
                setName(userInfo.name);
                setEmail(userInfo.email);
                setProfile(profiles.find(x => x.value === userInfo.profile));
                setPassword("");
            }
        },
    }));

    async function handleSaveUser(e) {
        e.preventDefault();

        setLoadingUserForm(true);

        setInvalidName(false);
        setInvalidEmail(false);
        setInvalidPassword(false);

        let hasError = false;

        if (!name) {
            setInvalidName(true);
            hasError = true;
        }

        if (!email) {
            setInvalidEmailMessage("Informe o e-mail do usuário");
            setInvalidEmail(true);
            hasError = true;
        } else {
            let validateEmail = /\S+@\S+\.\S+/;
            if (!validateEmail.test(email)) {
                setInvalidEmailMessage("Informe um e-mail válido");
                setInvalidEmail(true);
                hasError = true;
            }
        }

        if (!profile) {
            setProfile(profiles.find(x => x.value === "consultant"));
        }

        if (!isUpdate && !password) {
            setInvalidPassword(true);
            hasError = true;
        }

        if (!hasError) {
            const user = {
                name,
                email,
                profile: profile.value,
                status: active
            };

            if (!isUpdate) {
                user.password = password;

                try {
                    await api.post("/users", user)
                        .then(response => {
                            if (response.status === 201) {
                                setLoadingUserForm(false);
                                notifySuccess();
                                resetFields();
                                onClose();
                                setModalBox(false);
                            }
                        })
                } catch (err) {
                    if (err.data.error === "ALREADY_EXISTS") {
                        setErrorMessage("Usúario já cadastrado!");
                    } else {
                        setErrorMessage("Ocorreu um problema ao salvar o registro");
                    }
                    notifyError();
                }
            } else {
                try {
                    await api.put(`/users/${userId}`, user)
                        .then(response => {
                            if (response.status === 200) {
                                setLoadingUserForm(false);
                                notifySuccess();
                                resetFields();
                                onClose();
                                setModalBox(false);
                            }
                        })
                } catch (err) {
                    notifyError();
                }
            }
        }

        setLoadingUserForm(false);
    }

    function handleCancel() {
        resetFields();
        setModalBox(false);
    }

    function resetFields() {
        setName("");
        setEmail("");
        setProfile(profiles.find(x => x.value === "consultant"));
        setPassword("");

        setActive(true);

        setInvalidName(false);
        setInvalidEmail(false);
        setInvalidEmailMessage("Informe o e-mail do usuário");
        setInvalidPassword(false);

        setErrorMessage("Ocorreu um problema ao salvar o registro");
    }

    function notifySuccess() {
        toast.success(`✔️ Usuário ${(isUpdate ? "alterado" : "incluído")} com sucesso!`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    function notifyError() {
        toast.error(`❌ ${errorMessage}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    return (
        <>
            <Modal size="xl" onHide={setModalBox} show={modalBox}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h3
                            className="modal-title"
                            id="exampleModalLabel"
                        >
                            {(!isUpdate ? "Novo " : "Alterar ")}Usuário
                        </h3>
                        <button
                            type="button"
                            className="btn-close"
                            onClick={() => setModalBox(false)}
                        ></button>
                    </div>
                    <div className="modal-body">
                        <div className="basic-form">
                            <div className="row" hidden={!isUpdate}>
                                <div className="form-group mb-3 col-md-6">
                                    <FormCheck
                                        type="switch"
                                        id="active"
                                        label="Ativo"
                                        defaultChecked={active}
                                        onChange={() => setActive(!active)}
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="form-group mb-12 col-md-12">
                                    <div className="row">
                                        <div className="form-group mb-3 col-md-6">
                                            <label>Nome</label>
                                            <input
                                                autoFocus
                                                type="text"
                                                className={`form-control ${(invalidName ? "is-invalid" : "")}`}
                                                value={name}
                                                onChange={e => setName(e.target.value)}
                                            />
                                            <DFormText hidden={!invalidName} color="danger">Informe o nome do usuário</DFormText>
                                        </div>
                                        <div className="form-group mb-3 col-md-6">
                                            <label>E-mail</label>
                                            <input
                                                type="email"
                                                className={`form-control ${(invalidEmail ? "is-invalid" : "")}`}
                                                value={email}
                                                onChange={e => setEmail(e.target.value)}
                                                disabled={isUpdate}
                                            />
                                            <DFormText hidden={!invalidEmail} color="danger">{invalidEmailMessage}</DFormText>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group mb-3 col-md-6">
                                            <label>Perfil</label>
                                            <Select
                                                id="profile"
                                                defaultValue={profile}
                                                value={profile}
                                                onChange={e => setProfile(e)}
                                                options={profiles}
                                                styles={{
                                                    control: (baseStyles, state) => ({
                                                        ...baseStyles,
                                                        height: 45,
                                                        backgroundColor: state.isDisabled ? "#dde0e3" : ""
                                                    }),
                                                }}
                                                placeholder="Selecione..."
                                            />
                                        </div>
                                        <div className="form-group mb-3 col-md-6">
                                            <label>Senha</label>
                                            <input
                                                type="password"
                                                className={`form-control ${(invalidPassword ? "is-invalid" : "")}`}
                                                value={password}
                                                onChange={e => setPassword(e.target.value)}
                                            />
                                            <DFormText hidden={!invalidPassword} color="danger">Informe a senha para acesso</DFormText>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <Button
                            variant="outline-danger btn-rounded"
                            onClick={handleCancel}
                        >
                            Fechar
                        </Button>
                        <Button
                            variant="success btn-rounded"
                            onClick={handleSaveUser}
                        >
                            Salvar
                        </Button>
                    </div>
                </div>
            </Modal>

            <ToastContainer />

            <ModalLoading
                visible={loadingUserForm}
                message="Salvando Usuário..."
                onClose={setLoadingUserForm}
            />
        </>
    );
});

export default UserForm;
