import React, { forwardRef, useImperativeHandle, useRef, useState } from "react";
import {
    Button,
    Modal,
    Table
} from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import InputMask from "react-input-mask";
import swal from "sweetalert";
import dayjs from "dayjs";

import { v4 as uuidv4 } from "uuid";

import ActivityForm from "./ActivityForm.js";
import ModalLoading from "../../components/ModalLoading/ModalLoading";
import DFormText from "../../components/DFormText/DFormText.js";

import { calculateWorkTime, timeToMinutes } from "../../../services/utils.js";
import api from "../../../services/api.js";

const WorkOrdersForm = forwardRef((props, ref) => {
    const { onClose } = props;
    const activityFormRef = useRef();
    const [modalBox, setModalBox] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [loadingWorkOrdersForm, setLoadingWorkOrdersForm] = useState(false);

    const [workOrderId, setWorkOrderId] = useState("");
    const [scheduleDate, setScheduleDate] = useState("");
    const [scheduleId, setScheduleId] = useState("");
    const [workOrderDate, setWorkOrderDate] = useState("");
    const [customerId, setCustomerId] = useState("");
    const [customer, setCustomer] = useState("");
    const [userId, setUserId] = useState("");
    const [user, setUser] = useState("");
    const [customerUser, setCustomerUser] = useState("");
    const [initialTime, setInitialTime] = useState("00:00");
    const [finalTime, setFinalTime] = useState("00:00");
    const [transfer, setTransfer] = useState("00:00");
    const [discount, setDiscount] = useState("00:00");
    const [totalTime, setTotalTime] = useState("00:00");

    const [projectsList, setProjectsList] = useState([]);
    const [activitiesList, setActivitiesList] = useState([]);

    const [invalidInitialTime, setInvalidInitialTime] = useState(false);
    const [invalidFinalTime, setInvalidFinalTime] = useState(false);
    const [invalidFinalTimeMessage, setInvalidFinalTimeMessage] = useState("Informe o horário final");
    const [invalidUser, setInvalidUser] = useState(false);
    const [invalidActivity, setInvalidActivity] = useState(false);
    const [invalidActivityMessage, setInvalidActivityMessage] = useState("Informe pelo menos uma atividade");

    useImperativeHandle(ref, () => ({
        async openModal(mode, workOrderInfo = {}) {
            setModalBox(true);
            setIsUpdate(false);

            getProjects(workOrderInfo.customer_id);

            setWorkOrderDate(dayjs(workOrderInfo.schedule_date).format("DD/MM/YYYY"));
            setScheduleDate(dayjs(workOrderInfo.schedule_date).toDate());
            setScheduleId(workOrderInfo.schedule_id);
            setCustomerId(workOrderInfo.customer_id);
            setCustomer(workOrderInfo.customer_name);
            setUserId(workOrderInfo.user_id);
            setUser(workOrderInfo.user_name);

            if (mode === "update") {
                setIsUpdate(true);
                setWorkOrderId(workOrderInfo.id);
                setInitialTime(workOrderInfo.start_time);
                setFinalTime(workOrderInfo.end_time);
                setTransfer(workOrderInfo.trip_time);
                setDiscount(workOrderInfo.discount_time);
                setCustomerUser(workOrderInfo.customer_user);

                const activitiesListReturned = workOrderInfo.activities.map(activityItem => {
                    return {
                        innerId: uuidv4(),
                        projectId: activityItem.project_id,
                        projectDescription: activityItem.project_description,
                        taskId: activityItem.task_id,
                        taskDescription: activityItem.task_description,
                        totalTime: activityItem.total_time,
                        activity: activityItem.activity
                    }
                })
                setActivitiesList(activitiesListReturned);

                calcTotalTime(workOrderInfo.start_time, workOrderInfo.end_time, workOrderInfo.trip_time, workOrderInfo.discount_time);
            }
        },
    }));

    async function getProjects(id) {
        await api.get(`/projects?customer=${id}`)
            .then(response => {
                if (response.status === 200) {
                    const projectsReturned = response.data.projects.map(projectItem => {
                        return {
                            value: projectItem._id,
                            label: projectItem.description,
                            status: projectItem.status,
                            tasks: projectItem.tasks
                        }
                    });

                    setProjectsList(projectsReturned);
                }
            })
    }

    async function handleSave(e) {
        e.preventDefault();

        setLoadingWorkOrdersForm(true);

        setInvalidInitialTime(false);
        setInvalidFinalTime(false);
        setInvalidUser(false);
        setInvalidActivity(false);
        setInvalidActivityMessage("Informe pelo menos uma atividade");

        let hasError = false;

        if (initialTime === "00:00") {
            setInvalidInitialTime(true);
            hasError = true;
        }

        if (finalTime === "00:00") {
            setInvalidFinalTimeMessage("Informe o horário final")
            setInvalidFinalTime(true);
            hasError = true;
        } else {
            const initialTimeInMinutes = timeToMinutes(initialTime);
            const finalTimeInMinutes = timeToMinutes(finalTime);

            if (finalTimeInMinutes > 0 && finalTimeInMinutes < initialTimeInMinutes) {
                setInvalidFinalTimeMessage("A hora final deve ser maior que a inicial");
                setInvalidFinalTime(true);
                hasError = true;
            }
        }

        if (!customerUser) {
            setInvalidUser(true);
            hasError = true;
        }

        if (activitiesList.length === 0) {
            setInvalidActivity(true);
            hasError = true;
        } else {
            const totalActivitiesTime = activitiesList.reduce((acc, curr) => {
                return acc + timeToMinutes(curr.totalTime);
            }, 0);

            if (totalActivitiesTime < timeToMinutes(totalTime)) {
                setInvalidActivityMessage("Faltam horas a serem apontadas da OS");
                setInvalidActivity(true);
                hasError = true;
            }
        }

        if (!hasError) {
            let workOrder = {
                start_time: initialTime,
                end_time: finalTime,
                trip_time: transfer,
                discount_time: discount,
                customer_user: customerUser,
                activities: activitiesList.map(activityItem => {
                    return {
                        project_id: activityItem.projectId,
                        project_description: activityItem.projectDescription,
                        task_id: activityItem.taskId,
                        task_description: activityItem.taskDescription,
                        total_time: activityItem.totalTime,
                        activity: activityItem.activity
                    }
                })
            }

            if (!isUpdate) {
                workOrder.status = "sent";
                workOrder.schedule_id = scheduleId;
                workOrder.schedule_date = scheduleDate;
                workOrder.user_id = userId;
                workOrder.user_name = user;
                workOrder.customer_id = customerId;
                workOrder.customer_name = customer;

                try {
                    await api.post("/workorders", workOrder)
                        .then(response => {
                            if (response.status === 201) {
                                setLoadingWorkOrdersForm(false);
                                notifySuccess();
                                resetFields();
                                onClose();
                                setModalBox(false);
                            }
                        })
                } catch (err) {
                    notifyError();
                }
            } else {
                try {
                    await api.put(`/workorders/${workOrderId}`, workOrder)
                        .then(response => {
                            if (response.status === 200) {
                                setLoadingWorkOrdersForm(false);
                                notifySuccess();
                                resetFields();
                                onClose();
                                setModalBox(false);
                            }
                        })
                } catch (err) {
                    notifyError();
                }
            }
        }

        setLoadingWorkOrdersForm(false);
    }

    function handleCancel() {
        resetFields();
        setModalBox(false);
    }

    function resetFields() {
        setScheduleDate("");
        setWorkOrderDate("");
        setCustomerId("");
        setCustomer("");
        setUserId("");
        setUser("");
        setCustomerUser("");
        setInitialTime("00:00");
        setFinalTime("00:00");
        setTransfer("00:00");
        setDiscount("00:00");
        setTotalTime("00:00");
        setActivitiesList([]);

        setInvalidInitialTime(false);
        setInvalidFinalTime(false);
        setInvalidFinalTimeMessage("Informe o horário final");
        setInvalidUser(false);
        setInvalidActivity(false);
        setInvalidActivityMessage("Informe pelo menos uma atividade");
    }

    function notifySuccess() {
        toast.success(`✔️ OS ${(isUpdate ? "alterada" : "incluída")} com sucesso!`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    function notifyError() {
        toast.error(`❌ Ocorreu um problema ao gravar o registro`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    function calcTotalTime(startTime = initialTime, endTime = finalTime, tripTime = transfer, discountTime = discount) {
        setInvalidFinalTime(false);

        const totalTimeCalculated = calculateWorkTime(startTime, endTime, tripTime, discountTime);

        if (totalTimeCalculated === "") {
            setInvalidFinalTimeMessage("A hora final deve ser maior que a inicial");
            setInvalidFinalTime(true);
            return;
        }

        setTotalTime(totalTimeCalculated);
    }

    function onValidateActivity(id, activityTime) {
        const activityFiltered = activitiesList.filter(x => x.innerId !== id);

        let totalActivityTime = timeToMinutes(activityTime);

        if (activityFiltered) {
            for (let activityItem of activityFiltered) {
                totalActivityTime += timeToMinutes(activityItem.totalTime);
            }
        }

        if (totalActivityTime > timeToMinutes(totalTime)) {
            return false;
        }

        return true;
    }

    function onCloseActivity(activity) {
        let newActivitiesList = [...activitiesList];
        const activityIndex = newActivitiesList.findIndex(x => x.innerId === activity.innerId);

        if (activityIndex < 0) {
            newActivitiesList.push(activity);
        } else {
            newActivitiesList[activityIndex].projectId = activity.projectId;
            newActivitiesList[activityIndex].projectDescription = activity.projectDescription;
            newActivitiesList[activityIndex].taskId = activity.taskId;
            newActivitiesList[activityIndex].taskDescription = activity.taskDescription;
            newActivitiesList[activityIndex].totalTime = activity.totalTime;
            newActivitiesList[activityIndex].activity = activity.activity;
        }

        setActivitiesList(newActivitiesList);
    }

    function handleDeleteActivity(id) {
        swal({
            title: "Confirma a exclusão da atividade?",
            icon: "warning",
            buttons: ["Cancelar", "Confirmar"],
            dangerMode: true
        }).then(async (willDelete) => {
            if (willDelete) {
                const newActivitiesList = activitiesList.filter(x => x.innerId !== id);
                setActivitiesList(newActivitiesList);
            } else {
                return
            }
        })
    }

    return (
        <>
            <Modal size="xl" onHide={setModalBox} show={modalBox}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h3
                            className="modal-title"
                            id="exampleModalLabel"
                        >
                            {(!isUpdate ? "Nova " : "Alterar ")}OS
                        </h3>
                        <button
                            type="button"
                            className="btn-close"
                            onClick={() => setModalBox(false)}
                        ></button>
                    </div>
                    <div className="modal-body">
                        <div className="basic-form">
                            <div className="row">
                                <div className="form-group mb-3 col-md-3">
                                    <label>Data</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={workOrderDate}
                                        disabled
                                    />
                                </div>
                                <div className="form-group mb-3 col-md-9">
                                    <label>Cliente</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={customer}
                                        disabled
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group mb-3 col-md-12">
                                    <label>Usuário</label>
                                    <input
                                        type="text"
                                        autoFocus
                                        className={`form-control ${(invalidUser ? "is-invalid" : "")}`}
                                        value={customerUser}
                                        onChange={e => setCustomerUser(e.target.value)}
                                    />
                                    <DFormText hidden={!invalidUser} color="danger">Informe o nome do usuário</DFormText>
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group mb-3 col-md-2">
                                    <label>Hora Inicial</label>
                                    <InputMask
                                        mask="99:99"
                                        maskChar=" "
                                        value={initialTime}
                                        onChange={(event) => {
                                            setInitialTime(event?.target.value);
                                        }}
                                        alwaysShowMask={true}
                                        onFocus={e => e.target.select()}
                                        onBlur={() => calcTotalTime()}
                                    >
                                        {(inputProps) => <input {...inputProps} type="text" className={`text-end form-control ${(invalidInitialTime ? "is-invalid" : "")}`} />}
                                    </InputMask>
                                    <DFormText hidden={!invalidInitialTime} color="danger">Informe o horário inicial</DFormText>
                                </div>
                                <div className="form-group mb-3 col-md-2">
                                    <label>Hora Final</label>
                                    <InputMask
                                        mask="99:99"
                                        maskChar=" "
                                        value={finalTime}
                                        onChange={(event) => {
                                            setFinalTime(event?.target.value);
                                        }}
                                        alwaysShowMask={true}
                                        onBlur={() => calcTotalTime()}
                                    >
                                        {(inputProps) => <input {...inputProps} type="text" className={`text-end form-control ${(invalidFinalTime ? "is-invalid" : "")}`} />}
                                    </InputMask>
                                    <DFormText hidden={!invalidFinalTime} color="danger">{invalidFinalTimeMessage}</DFormText>
                                </div>
                                <div className="form-group mb-3 col-md-2">
                                    <label>Traslado</label>
                                    <InputMask
                                        mask="99:99"
                                        maskChar=" "
                                        value={transfer}
                                        onChange={(event) => {
                                            setTransfer(event?.target.value);
                                        }}
                                        alwaysShowMask={true}
                                        onBlur={() => calcTotalTime()}
                                    >
                                        {(inputProps) => <input {...inputProps} type="text" className="text-end form-control" />}
                                    </InputMask>
                                </div>
                                <div className="form-group mb-3 col-md-2">
                                    <label>Descontos</label>
                                    <InputMask
                                        mask="99:99"
                                        maskChar=" "
                                        value={discount}
                                        onChange={(event) => {
                                            setDiscount(event?.target.value);
                                        }}
                                        alwaysShowMask={true}
                                        onBlur={() => calcTotalTime()}
                                    >
                                        {(inputProps) => <input {...inputProps} type="text" className="text-end form-control" />}
                                    </InputMask>
                                </div>
                                <div className="form-group mb-3 col-md-2">
                                    <label>Total</label>
                                    <InputMask
                                        mask="99:99"
                                        maskChar=" "
                                        value={totalTime}
                                        alwaysShowMask={true}
                                        disabled
                                    >
                                        {(inputProps) => <input {...inputProps} type="text" className="text-end form-control" disabled />}
                                    </InputMask>
                                </div>
                            </div>

                            <div className="row">
                                <div className="form-group mb-3 col-md-11">
                                    <h4>Atividades</h4> <DFormText hidden={!invalidActivity} color="danger">{invalidActivityMessage}</DFormText>
                                </div>
                                <div className="form-group mb-3 col-md-1">
                                    <button
                                        className="btn btn-success shadow sharp ms-1"
                                        title="Incluir Atividade"
                                        onClick={() => activityFormRef.current.openModal("new", { customer_id: customerId }, projectsList)}
                                    >
                                        <i className="fas fa-plus"></i>
                                    </button>
                                </div>
                            </div>

                            <div className="row">
                                <div className="form-group mb-3 col-md-12">
                                    <Table responsive>
                                        <thead>
                                            <tr key="osHeader">
                                                <th>
                                                    <strong>Projeto</strong>
                                                </th>
                                                <th>
                                                    <strong>Tarefa</strong>
                                                </th>
                                                <th>
                                                    <strong>Tempo</strong>
                                                </th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                activitiesList.map(activityItem => {
                                                    return (
                                                        <tr key={activityItem.innerId}>
                                                            <td>
                                                                <div className="d-flex align-items-center">
                                                                    <span className="w-space-no">{activityItem.projectDescription}</span>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="d-flex align-items-center">
                                                                    <span className="w-space-no">{activityItem.taskDescription}</span>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="d-flex align-items-right">
                                                                    <span className="w-space-no">{activityItem.totalTime}</span>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="d-flex">
                                                                    <button
                                                                        className="btn btn-primary shadow sharp ms-1"
                                                                        title="Editar atividade"
                                                                        onClick={() => activityFormRef.current.openModal("update", { ...activityItem, customer_id: customerId }, projectsList)}
                                                                    >
                                                                        <i className="fas fa-pencil-alt"></i>
                                                                    </button>
                                                                    <button
                                                                        className="btn btn-danger shadow sharp ms-1"
                                                                        title="Excluir atividade"
                                                                        onClick={() => handleDeleteActivity(activityItem.innerId)}
                                                                    >
                                                                        <i className="fa fa-trash"></i>
                                                                    </button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <Button
                            variant="outline-danger btn-rounded"
                            onClick={handleCancel}
                        >
                            Fechar
                        </Button>
                        <Button
                            variant="success btn-rounded"
                            onClick={handleSave}
                        >
                            Salvar
                        </Button>
                    </div>
                </div>
            </Modal>

            <ToastContainer />

            <ActivityForm
                ref={activityFormRef}
                onValidation={onValidateActivity}
                onClose={onCloseActivity}
            />

            <ModalLoading
                visible={loadingWorkOrdersForm}
                message="Gravando OS..."
                onClose={setLoadingWorkOrdersForm}
            />
        </>
    );
});

export default WorkOrdersForm;
