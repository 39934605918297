import React, { forwardRef, useImperativeHandle, useState } from "react";
import {
    Button,
    Modal
} from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import swal from "sweetalert";
import Select from "react-select";
import dayjs from "dayjs";

import ModalLoading from "../../components/ModalLoading/ModalLoading";
import DFormText from "../../components/DFormText/DFormText.js";

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ptBR from "date-fns/locale/pt-BR";
import api from "../../../services/api.js";
import { workPlaces } from "../../../enum/enums.js";
registerLocale("ptBR", ptBR);

const ScheduleForm = forwardRef((props, ref) => {
    const { onClose } = props;
    const [modalBox, setModalBox] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [loadingScheduleForm, setLoadingScheduleForm] = useState(false);

    const [scheduleId, setScheduleId] = useState("");

    const [usersList, setUsersList] = useState([]);
    const [customersList, setCustomersList] = useState([]);

    const [scheduleDate, setScheduleDate] = useState("");
    const [user, setUser] = useState(null);
    const [customer, setCustomer] = useState(null);
    const [place, setPlace] = useState(null);
    const [observations, setObservations] = useState("");
    const [status, setStatus] = useState("pending");

    const [invalidScheduleDate, setInvalidScheduleDate] = useState(false);
    const [invalidUser, setInvalidUser] = useState(false);
    const [invalidCustomer, setInvalidCustomer] = useState(false);
    const [invalidPlace, setInvalidPlace] = useState(false);
    var userInfo = undefined;

    if (localStorage.getItem("xDetRem")) {
        if (localStorage.getItem("xDetUsr")) {
            userInfo = JSON.parse(localStorage.getItem("xDetUsr"));
        }
    } else {
        if (sessionStorage.getItem("xDetUsr")) {
            userInfo = JSON.parse(sessionStorage.getItem("xDetUsr"));
        }
    }

    var errorMessage = "Ocorreu um problema ao salvar o registro";

    useImperativeHandle(ref, () => ({
        async openModal(mode, schedule_id = "") {
            setModalBox(true);
            setIsUpdate(false);

            getUsers();
            getCustomers();

            if (mode === "update") {
                setIsUpdate(true);
                await loadSchedule(schedule_id);
            }

            console.log(userInfo)

            if (userInfo.profile === "consultant") {
                setUser({ value: userInfo.user_id, label: userInfo.name });
            }
        },
    }));

    async function getUsers() {
        await api.get("/users?status=true&offset=1&limit=100")
            .then(response => {
                if (response.status === 200) {
                    const usersFiltered = response.data.users.filter(x => x.name !== "Administrador");
                    const usersReturned = usersFiltered.map(userInfo => {
                        return {
                            value: userInfo._id,
                            label: userInfo.name
                        }
                    });

                    setUsersList(usersReturned);
                }
            })
    }

    async function getCustomers() {
        await api.get("/customers?status=true&offset=1&limit=100")
            .then(response => {
                if (response.status === 200) {
                    const customersReturned = response.data.customers.map(userInfo => {
                        return {
                            value: userInfo._id,
                            label: userInfo.name,
                            addresses: userInfo.addresses
                        }
                    });

                    setCustomersList(customersReturned);
                }
            })
    }

    async function loadSchedule(id) {
        await api.get(`/schedules/${id}`)
            .then(response => {
                if (response.status === 200) {
                    setScheduleId(id);
                    setScheduleDate(dayjs(response.data.schedule_date).toDate());
                    setUser({ value: response.data.user_id, label: response.data.user_name });
                    setCustomer({ value: response.data.customer_id, label: response.data.customer_name });
                    setPlace(response.data.place_id ? { value: response.data.place_id, label: response.data.place_description } : null);
                    setObservations(response.data.observation);
                    setStatus(response.data.status);
                }
            })
    }

    async function handleSaveSchedule(e) {
        e.preventDefault();

        setInvalidScheduleDate(false);
        setInvalidUser(false);
        setInvalidCustomer(false);
        setInvalidPlace(false);

        let hasError = false;

        if (!scheduleDate) {
            setInvalidScheduleDate(true);
            hasError = true;
        }

        if (!user) {
            setInvalidUser(true);
            hasError = true;
        }

        if (!customer) {
            setInvalidCustomer(true);
            hasError = true;
        }

        if (!place) {
            setInvalidPlace(true);
            hasError = true;
        }

        if (!hasError) {
            let schedule = {
                schedule_date: dayjs(scheduleDate).toDate(),
                user_id: user.value,
                user_name: user.label,
                customer_id: customer.value,
                customer_name: customer.label,
                place_id: place.value,
                place_description: place.label,
                observation: observations
            };

            if (!isUpdate) {
                schedule.status = "pending";

                try {
                    await api.post("/schedules", schedule)
                        .then(response => {
                            if (response.status === 201) {
                                setLoadingScheduleForm(false);
                                notifySuccess();
                                resetFields();
                                onClose();
                                setModalBox(false);
                            }
                        })
                } catch (err) {
                    if (err.data.error === "ALREADY_EXISTS") {
                        errorMessage = "Já existe agendamento nesta data para o mesmo cliente";
                    }
                    notifyError();
                }
            } else {
                try {
                    await api.put(`/schedules/${scheduleId}`, schedule)
                        .then(response => {
                            if (response.status === 200) {
                                setLoadingScheduleForm(false);
                                notifySuccess();
                                resetFields();
                                onClose();
                                setModalBox(false);
                            }
                        })
                } catch (err) {
                    errorMessage = "Ocorreu um problema ao salvar o registro";
                    notifyError();
                }
            }
        }
    }

    function handleCancelSchedule() {
        resetFields();
        setModalBox(false);
    }

    function resetFields() {
        setScheduleId("");
        setScheduleDate("");
        setUser(null);
        setCustomer(null);
        setObservations("");

        setInvalidScheduleDate(false);
        setInvalidUser(false);
        setInvalidCustomer(false);
        errorMessage = "Ocorreu um problema ao salvar o registro";
    }

    function notifySuccess() {
        toast.success(`✔️ Agenda ${(isUpdate ? "alterada" : "incluída")} com sucesso!`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    function notifyError() {
        toast.error(`❌ ${errorMessage}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    function handleDeleteSchedule() {
        swal({
            title: "Confirma a exclusão da agenda?",
            icon: "warning",
            buttons: ["Cancelar", "Confirmar"],
            dangerMode: true
        }).then(async (willDelete) => {
            if (willDelete) {
                setLoadingScheduleForm(true);

                try {
                    await api.delete(`/schedules/${scheduleId}`)
                        .then(response => {
                            if (response.status === 200) {
                                notifySuccess();
                                resetFields();
                                onClose();
                                setModalBox(false);
                            }
                        })
                } catch (err) {
                    notifyError();
                }

                setLoadingScheduleForm(false);
            } else {
                return
            }
        })
    }

    return (
        <>
            <Modal size="xl" onHide={setModalBox} show={modalBox}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h3
                            className="modal-title"
                            id="exampleModalLabel"
                        >
                            {(!isUpdate ? "Nova " : "Alterar ")}Agenda
                        </h3>
                        <button
                            type="button"
                            className="btn-close"
                            onClick={() => setModalBox(false)}
                        ></button>
                    </div>
                    <div className="modal-body">
                        <div className="basic-form">
                            <div className="row">
                                <div className="form-group mb-3 col-md-3">
                                    <label>Data</label>
                                    <br />
                                    <DatePicker
                                        selected={scheduleDate}
                                        onChange={(date) => setScheduleDate(date)}
                                        locale="ptBR"
                                        dateFormat="P"
                                        className={`form-control ${(invalidScheduleDate ? " is-invalid" : "")}`}
                                    />
                                    <br />
                                    <DFormText hidden={!invalidScheduleDate} color="danger">Informe a data da agenda</DFormText>
                                </div>
                                <div className="form-group mb-3 col-md-9">
                                    <label>Consultor</label>
                                    <Select
                                        id="user"
                                        isClearable
                                        className={`${(invalidUser ? "form-control is-invalid" : "")}`}
                                        defaultValue={user}
                                        value={user}
                                        onChange={e => setUser(e)}
                                        options={usersList}
                                        styles={{
                                            control: (baseStyles, state) => ({
                                                ...baseStyles,
                                                height: 45,
                                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                                            }),
                                        }}
                                        placeholder="Selecione..."
                                        isDisabled={userInfo.profile === "consultant"}
                                    />
                                    <DFormText hidden={!invalidUser} color="danger">Informe o consultor</DFormText>
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group mb-3 col-md-6">
                                    <label>Cliente</label>
                                    <Select
                                        id="customer"
                                        isClearable
                                        className={`${(invalidCustomer ? "form-control is-invalid" : "")}`}
                                        defaultValue={customer}
                                        value={customer}
                                        onChange={e => setCustomer(e)}
                                        options={customersList}
                                        styles={{
                                            control: (baseStyles, state) => ({
                                                ...baseStyles,
                                                height: 45,
                                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                                            }),
                                        }}
                                        placeholder="Selecione..."
                                    />
                                    <DFormText hidden={!invalidCustomer} color="danger">Informe o cliente</DFormText>
                                </div>
                                <div className="form-group mb-3 col-md-6">
                                    <label>Local de Atendimento</label>
                                    <Select
                                        id="place"
                                        isClearable
                                        className={`${(invalidPlace ? "form-control is-invalid" : "")}`}
                                        defaultValue={place}
                                        value={place}
                                        onChange={e => setPlace(e)}
                                        options={workPlaces}
                                        styles={{
                                            control: (baseStyles, state) => ({
                                                ...baseStyles,
                                                height: 45,
                                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                                            }),
                                        }}
                                        placeholder="Selecione..."
                                    />
                                    <DFormText hidden={!invalidPlace} color="danger">Informe o local de atendimento</DFormText>
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group mb-3 col-md-12">
                                    <label>Observações</label>
                                    <textarea
                                        type="text"
                                        className="form-control"
                                        value={observations}
                                        onChange={e => setObservations(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer justify-content-between">
                        <div className="align-start">
                            <Button
                                variant="danger btn-rounded"
                                onClick={handleDeleteSchedule}
                                hidden={!isUpdate || status !== "pending"}
                            >
                                Excluir
                            </Button>
                        </div>
                        <div className="align-end">
                            <Button
                                className="me-2"
                                variant="outline-danger btn-rounded"
                                onClick={handleCancelSchedule}
                            >
                                Fechar
                            </Button>
                            <Button
                                variant="success btn-rounded"
                                onClick={handleSaveSchedule}
                                hidden={status !== "pending"}
                            >
                                Salvar
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal>

            <ToastContainer />

            <ModalLoading
                visible={loadingScheduleForm}
                message="Gravando Agenda..."
                onClose={setLoadingScheduleForm}
            />
        </>
    );
});

export default ScheduleForm;
