import React, { Fragment, useEffect, useRef, useState } from "react";
import PageTitle from "../../layouts/PageTitle";
import {
    Row,
    Col,
    Card,
    Table
} from "react-bootstrap";
import swal from "sweetalert";
import { ToastContainer, toast } from "react-toastify";

import ModalLoading from "../../components/ModalLoading/ModalLoading";
import DPagination from "../../components/DPagination/DPagination";
import UserForm from "./UserForm";
import { profiles } from "../../../enum/enums";

import api from "../../../services/api";

const Users = () => {
    const userFormRef = useRef();
    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);

    const [errorMessage, setErrorMessage] = useState("Ocorreu um problema ao excluir o usuário");

    useEffect(() => {
        getUsers(currentPage);
    }, [])

    async function getUsers(page = currentPage) {
        await api.get(`/users?offset=${page}&limit=10`)
            .then(response => {
                if (response.status === 200) {
                    const usersReturned = response.data.users.map(userInfo => {
                        const profileDescription = profiles.find(x => x.value === userInfo.profile);

                        return { ...userInfo, profileDescription };
                    })

                    setUsers(usersReturned);

                    const pages = Math.ceil(usersReturned.length / 10);
                    setTotalPages(Math.max(pages, 1));
                }
            })
    }

    function handleDeleteUser(user) {
        swal({
            title: `Confirma a exclusão do usuário ${user.name}?`,
            icon: "warning",
            buttons: ["Fechar", "Confirmar"],
            dangerMode: true
        }).then(async (willDelete) => {
            if (willDelete) {
                try {
                    await api.delete(`/users/${user._id}`)
                        .then(response => {
                            if (response.status === 200) {
                                notifySuccess();
                                getUsers();
                            }
                        })
                } catch (err) {
                    if (err.data.error === "USER_IN_USE") {
                        setErrorMessage("O usuário já possui movimentos e não poderá ser excluído!");
                    }
                    notifyError();
                }
            } else {
                return
            }
        })
    }

    function notifySuccess() {
        toast.success(`✔️ Usuário excluído com sucesso!`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    function notifyError() {
        toast.error(`❌ ${errorMessage}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    function setActivePage(page) {
        setCurrentPage(page);

        getUsers(page);
    }

    function onCloseModal() {
        getUsers();
    }

    return (
        <>
            <Fragment>
                <PageTitle activeMenu="Table" motherMenu="Bootstrap" />
                <Row>
                    <Col lg={12}>
                        <Card>
                            <Card.Header>
                                <Card.Title>Usuários</Card.Title>
                                <div>
                                    <button
                                        className="btn btn-primary ms-1"
                                        onClick={() => userFormRef.current.openModal()}
                                        title="Incluir Usuário"
                                    >
                                        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M12 3C7.05 3 3 7.05 3 12C3 16.95 7.05 21 12 21C16.95 21 21 16.95 21 12C21 7.05 16.95 3 12 3ZM12 19.125C8.1 19.125 4.875 15.9 4.875 12C4.875 8.1 8.1 4.875 12 4.875C15.9 4.875 19.125 8.1 19.125 12C19.125 15.9 15.9 19.125 12 19.125Z" fill="#FCFCFC" />
                                            <path d="M16.3498 11.0251H12.9748V7.65009C12.9748 7.12509 12.5248 6.67509 11.9998 6.67509C11.4748 6.67509 11.0248 7.12509 11.0248 7.65009V11.0251H7.6498C7.1248 11.0251 6.6748 11.4751 6.6748 12.0001C6.6748 12.5251 7.1248 12.9751 7.6498 12.9751H11.0248V16.3501C11.0248 16.8751 11.4748 17.3251 11.9998 17.3251C12.5248 17.3251 12.9748 16.8751 12.9748 16.3501V12.9751H16.3498C16.8748 12.9751 17.3248 12.5251 17.3248 12.0001C17.3248 11.4751 16.8748 11.0251 16.3498 11.0251Z" fill="#FCFCFC" />
                                        </svg> Novo
                                    </button>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <Table responsive>
                                    <thead>
                                        <tr>
                                            <th>
                                                <strong>Nome</strong>
                                            </th>
                                            <th>
                                                <strong>Email</strong>
                                            </th>
                                            <th>
                                                <strong>Perfil</strong>
                                            </th>
                                            <th>
                                                <strong>Status</strong>
                                            </th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            users.map(user => {
                                                return (
                                                    <tr key={user.id}>
                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                <span className="w-space-no">{user.name}</span>
                                                            </div>
                                                        </td>
                                                        <td>{user.email}</td>
                                                        <td>{user.profileDescription.label}</td>
                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                <i className={`fa fa-circle ${user.status ? "text-success" : "text-danger"} me-1`}></i>{" "}
                                                                {user.status ? "Ativo" : "Inativo"}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            {user.name !== "Administrador" ? (
                                                                <div className="d-flex">
                                                                    <button
                                                                        className="btn btn-primary shadow sharp ms-1"
                                                                        title="Editar cadastro"
                                                                        onClick={() => userFormRef.current.openModal("update", user)}
                                                                    >
                                                                        <i className="fas fa-pencil-alt"></i>
                                                                    </button>
                                                                    <button
                                                                        className="btn btn-danger shadow sharp ms-1"
                                                                        title="Excluir cadastro"
                                                                        onClick={() => handleDeleteUser(user)}
                                                                    >
                                                                        <i className="fa fa-trash"></i>
                                                                    </button>
                                                                </div>
                                                            ) : ""}
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </Table>

                                <DPagination
                                    activePage={currentPage}
                                    pages={totalPages}
                                    onActivePageChange={(i) => setActivePage(i)}
                                />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Fragment>

            <UserForm ref={userFormRef} onClose={onCloseModal} />

            <ToastContainer />

            <ModalLoading
                visible={loading}
                message="Carregando Usuários..."
                onClose={setLoading}
            />
        </>
    );
};

export default Users;
