import React from "react";

const Footer = ({ changeFooter }) => {
  let d = new Date();
  return (
    <div className={`footer ${changeFooter}`}>
      <div className="copyright">
        <p>
          {d.getFullYear()} © Desenvolvido por {" "}
          <a href="https://www.dettalles.com.br/" target="_blank" rel="noreferrer">
            Dettalles Consultoria
          </a>{" "}
        </p>
      </div>
    </div>
  );
};

export default Footer;
