export function hasAccess() {
    let userInfo = undefined;

    if (localStorage.getItem("xDetRem")) {
        if (localStorage.getItem("xDetUsr")) {
            userInfo = JSON.parse(localStorage.getItem("xDetUsr"));
        }
    } else {
        if (sessionStorage.getItem("xDetUsr")) {
            userInfo = JSON.parse(sessionStorage.getItem("xDetUsr"));
        }
    }

    if (userInfo) {
        if (userInfo.profile === "admin" || userInfo.profile === "manager") {
            return true;
        }
    }

    return false;
}

export function timeToMinutes(timeToConvert) {
    let timeInMinutes = 0;
    if (timeToConvert !== "") {
        var timeArray = timeToConvert.split(":");
        timeInMinutes = (+timeArray[0]) * 60 + (+timeArray[1]);
    }
    return timeInMinutes;
}

export function minutesToTime(minutesToConvert) {
    const hours = Math.floor(minutesToConvert / 60);
    const minutes = minutesToConvert % 60;
    return String(hours).padStart(2, "0") + ":" + String(minutes).padStart(2, "0");
}

export function calculateWorkTime(startTime, endTime, tripTime, discountTime) {
    const initialTimeInMinutes = timeToMinutes(startTime);
    const finalTimeInMinutes = timeToMinutes(endTime);
    const transferInMinutes = timeToMinutes(tripTime);
    const discountInMinutes = timeToMinutes(discountTime);
    let totalTime = "00:00";

    if (finalTimeInMinutes > 0 && finalTimeInMinutes < initialTimeInMinutes) {
        return "";
    }

    if (initialTimeInMinutes > 0 && finalTimeInMinutes > 0) {
        const totalTimeInMinutes = finalTimeInMinutes - initialTimeInMinutes + transferInMinutes - discountInMinutes;
        totalTime = minutesToTime(totalTimeInMinutes);
    }

    return totalTime;
}