import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom'
import { ToastContainer, toast } from "react-toastify";
import {
	loadingToggleAction, loginAction,
} from '../../store/actions/AuthActions';

// image
//import logo from "../../images/logo-full.png";
import BgImage from "../../images/bg.png";
//import BgFull from "../../images/logo-full.png";
import logo from "../../images/logo.png";
import logolight from "../../images/logo-light.png";
import pol from "../../images/pol.jpg";
//import { url } from 'inspector';


function Login(props) {
	const [email, setEmail] = useState("");
	let errorsObj = { email: "", password: "" };
	const [errors, setErrors] = useState(errorsObj);
	const [password, setPassword] = useState("");
	const [remember, setRemember] = useState(false);

	const dispatch = useDispatch();

	useEffect(() => {
		if (props.errorMessage) {
			notifyError();
		}
	}, [props.errorMessage])

	function onLogin(e) {
		e.preventDefault();
		let error = false;
		const errorObj = { ...errorsObj };
		if (email === '') {
			errorObj.email = 'Informe o e-mail';
			error = true;
		}
		if (password === '') {
			errorObj.password = 'Informe a senha';
			error = true;
		}
		setErrors(errorObj);
		if (error) {
			return;
		}
		dispatch(loadingToggleAction(true));
		dispatch(loginAction(email, password, remember, props.history));
	}

	const element = document.querySelector("body");
	let dataTheme = element.getAttribute("data-theme-version");

	function notifyError() {
		toast.error(`❌ ${props.errorMessage}`, {
			position: "top-right",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			draggable: true,
			progress: undefined,
		});
	}

	return (
		<div className="container h-100">
			<div className="row h-100 align-items-center justify-contain-center">
				<div className="col-xl-12">
					<div className="card">
						<div className="card-body p-0">
							<div className="row m-0">
								<div className="col-xl-6 col-md-6 sign text-center sign-bg" style={{ backgroundImage: 'url(' + pol + ')' }}>
									<div>
										<div className="text-center my-5">
											<Link to={"#"}>
												{/* <img src={BgFull} alt="" /> */}
												<img className="logo-abbr dark-logo" width="200" src={logo} alt="" />
												<img className="logo-abbr light-logo text-center m-auto" width="200" src={logolight} alt="" />
											</Link>
										</div>
										{
											dataTheme === "light" ?
												<img src={BgImage} className=" slideskew img-fix bitcoin-img sd-shape7"></img>
												:
												<img src={BgImage} className=" slideskew img-fix bitcoin-img sd-shape7"></img>
										}

									</div>
								</div>
								<div className="col-xl-6 col-md-6" >
									<div className="sign-in-your px-2">
										<h4 className="fs-20 ">Dettalles - Projetos</h4>
										<p />

										<form onSubmit={onLogin}>
											<div className="mb-3">
												<label className="mb-1"><strong>E-mail</strong></label>
												<input type="email" className="form-control"
													value={email}
													onChange={(e) => setEmail(e.target.value)}
													placeholder="Informe seu e-mail"
												/>

												{errors.email && <div className="text-danger fs-12">{errors.email}</div>}
											</div>
											<div className="mb-3">
												<label className="mb-1"><strong>Senha</strong></label>
												<input
													type="password"
													className="form-control"
													value={password}
													placeholder="Digite sua senha"
													onChange={(e) =>
														setPassword(e.target.value)
													}
												/>
												{errors.password && <div className="text-danger fs-12">{errors.password}</div>}
											</div>
											<div className="row d-flex justify-content-between mt-4 mb-2">
												<div className="mb-3">
													<div className="form-check custom-checkbox ms-1">
														<input
															type="checkbox"
															className="form-check-input"
															id="basic_checkbox_1"
															value={remember}
															onChange={() => setRemember(!remember)}
														/>
														<label className="form-check-label" htmlFor="basic_checkbox_1">Lembrar</label>
													</div>
												</div>
											</div>
											<div className="text-center">
												<button type="submit" className="btn btn-primary btn-block">Entrar</button>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<ToastContainer />
		</div>
	);
}

const mapStateToProps = (state) => {
	return {
		errorMessage: state.auth.errorMessage,
		successMessage: state.auth.successMessage,
		showLoading: state.auth.showLoading,
	};
};
export default connect(mapStateToProps)(Login);
