import React, { useContext, useState, useEffect } from "react";

/// React router dom
import { Switch, Route } from "react-router-dom";

/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";
import ScrollToTop from "./layouts/ScrollToTop";
import Main from './layouts/Main';

import Home from "./components/Dashboard/Home";

// Cadastros
import Users from "./pages/Users/Users";
import Customers from "./pages/Customers/Customers";
import Projects from "./pages/Projects/Projects";

// Agenda
import Schedule from "./pages/Schedule/Schedule";
import WorkOrders from "./pages/WorkOrders/WorkOrders";

/// Pages
import Login from "./pages/Login";
import ForgotPassword from "./pages/ForgotPassword";
import Error404 from "./pages/Error404";

const Markup = () => {

  const routes = [
    { url: "login", component: Login },
    
    { url: "dashboard", component: Home },
    
    { url: "users", component: Users },
    { url: "customers", component: Customers },
    { url: "projects", component: Projects },
    { url: "schedule", component: Schedule },
    { url: "orders", component: WorkOrders },
  ];
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];


  let pagePath = path.split("-").includes("page");

  return (
    <>
      <Switch>
        {/* without layouts (nav, header, sidebar and footer) */}
        <Route path='/login' component={Login} />
        <Route path='/page-forgot-password' component={ForgotPassword} />
        <Route path='/page-error-404' component={Error404} />

        <Route path={['/', '/dashboard', '/user', '/dashboard-dark',
          '/header-theme', '/sidebar-compact',
          '/horizontal-sidebar', '/horizontal-theme',
          '/sidebar-mini'
        ]}
          exact
        >
          <div id="main-wrapper" className="active show">
            <Main>
              <Nav />
              <div className="content-body" style={{ minHeight: window.screen.height + 20 }}>
                <div className="container-fluid">
                  <Switch>
                    <Route path='/' exact component={Home} />
                    <Route path='/dashboard' exact component={Home} />

                    <Route path='/users' exact component={Users} />
                    <Route path='/customers' exact component={Customers} />
                    <Route path='/projects' exact component={Projects} />
                    <Route path='/schedule' exact component={Schedule} />
                    <Route path='/orders' exact component={WorkOrders} />
                  </Switch>
                  <Footer />
                </div>
              </div>
            </Main>
          </div>
        </Route>
        <Route path={['/container-boxed']} exact>
          <div id="main-wrapper" className=" show">
            <Main>
              <Nav />
              <div className="content-body" style={{ minHeight: window.screen.height + 20 }}>
                <div className="container-fluid">
                  <Switch>
                    {/* <Route path='/container-boxed' exact component={Theme2} />                                                      */}
                  </Switch>
                </div>
              </div>
              <Footer changeFooter="out-footer" />
            </Main>
          </div>
        </Route>

        {/* default layout  */}
        <Route>
          <div id="main-wrapper" className="show">
            <Main>
              <Nav />
              <div className="content-body" style={{ minHeight: window.screen.height + 20 }}>
                <div className="container-fluid">
                  <Switch>
                    {routes.map((data, i) => (
                      <Route key={i} exact path={`/${data.url}`} component={data.component} />
                    ))}
                  </Switch>
                  <Footer />
                </div>
              </div>
            </Main>
          </div>
        </Route>
      </Switch>
      <ScrollToTop />
    </>
  );
};

export default Markup;
