import React, { useRef } from 'react';

//comp
import ScheduleForm from './ScheduleForm';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import brLocale from "@fullcalendar/core/locales/pt-br";
import api from '../../../services/api';
import dayjs from 'dayjs';

const Schedule = () => {
    const calendarRef = useRef(null);
    const scheduleFormRef = useRef();
    var userInfo = undefined;

    if (localStorage.getItem("xDetRem")) {
        if (localStorage.getItem("xDetUsr")) {
            userInfo = JSON.parse(localStorage.getItem("xDetUsr"));
        }
    } else {
        if (sessionStorage.getItem("xDetUsr")) {
            userInfo = JSON.parse(sessionStorage.getItem("xDetUsr"));
        }
    }

    async function getEvents(info, successCallBack, failureCallback) {
        const { startStr, endStr } = info;
        const userFilter = (userInfo.profile === "consultant" ? `&user=${userInfo.user_id}` : "");

        try {
            await api.get(`/schedules?startDate=${startStr}&endDate=${endStr}${userFilter}`)
                .then(response => {
                    if (response.status === 200) {
                        const calendarEvents = response.data.schedules.map(scheduleInfo => {
                            const eventTitle = `${scheduleInfo.user_name.toUpperCase()} - ${scheduleInfo.customer_name.toUpperCase()}`;

                            return {
                                title: eventTitle,
                                start: dayjs(scheduleInfo.schedule_date).toDate(),
                                allDay: true,
                                id: scheduleInfo._id
                            }
                        })

                        successCallBack(calendarEvents);
                    }
                })
        } catch (err) {
            failureCallback();
        }
    }

    function eventClick(eventClicked) {
        scheduleFormRef.current.openModal("update", eventClicked.event.id);
    }

    function onCloseModal() {
        if (calendarRef.current) {
            calendarRef.current.getApi().refetchEvents();
        }
    }

    return (
        <>
            <div className="row">
                <div className="col-xl-12">
                    <div className="col-xl-12">
                        <div className="page-titles">
                            <div className="d-flex align-items-center">
                                <h2 className="heading">Agenda</h2>
                            </div>
                            <div>
                                <button className="btn btn-primary ms-1" onClick={() => scheduleFormRef.current.openModal()}>
                                    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12 3C7.05 3 3 7.05 3 12C3 16.95 7.05 21 12 21C16.95 21 21 16.95 21 12C21 7.05 16.95 3 12 3ZM12 19.125C8.1 19.125 4.875 15.9 4.875 12C4.875 8.1 8.1 4.875 12 4.875C15.9 4.875 19.125 8.1 19.125 12C19.125 15.9 15.9 19.125 12 19.125Z" fill="#FCFCFC" />
                                        <path d="M16.3498 11.0251H12.9748V7.65009C12.9748 7.12509 12.5248 6.67509 11.9998 6.67509C11.4748 6.67509 11.0248 7.12509 11.0248 7.65009V11.0251H7.6498C7.1248 11.0251 6.6748 11.4751 6.6748 12.0001C6.6748 12.5251 7.1248 12.9751 7.6498 12.9751H11.0248V16.3501C11.0248 16.8751 11.4748 17.3251 11.9998 17.3251C12.5248 17.3251 12.9748 16.8751 12.9748 16.3501V12.9751H16.3498C16.8748 12.9751 17.3248 12.5251 17.3248 12.0001C17.3248 11.4751 16.8748 11.0251 16.3498 11.0251Z" fill="#FCFCFC" />
                                    </svg> Adicionar
                                </button>
                            </div>

                        </div>
                    </div>

                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-body">
                                <div id="calendar" className="app-fullcalendar">
                                    <FullCalendar
                                        ref={calendarRef}
                                        defaultView="dayGridMonth"
                                        headerToolbar={{
                                            start: "prev,next today",
                                            center: "title",
                                            end: "dayGridMonth,timeGridWeek,timeGridDay",
                                        }}
                                        rerenderDelay={10}
                                        eventDurationEditable={false}
                                        editable={true}
                                        droppable={true}
                                        plugins={[
                                            dayGridPlugin,
                                            timeGridPlugin,
                                            interactionPlugin,
                                        ]}
                                        events={getEvents}
                                        eventClick={eventClick}
                                        eventTimeFormat={{ hour: '2-digit', minute: '2-digit', meridiem: false }}
                                        locale={brLocale}
                                        height={700}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ScheduleForm ref={scheduleFormRef} onClose={onCloseModal} />
        </>
    )
}

export default Schedule;