import React, { Fragment, useEffect, useRef, useState } from "react";
import PageTitle from "../../layouts/PageTitle";
import {
    Row,
    Col,
    Card,
    Table,
    Button,
} from "react-bootstrap";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import swal from "sweetalert";
import dayjs from "dayjs";

import ModalLoading from "../../components/ModalLoading/ModalLoading";
import ProjectForm from "./ProjectForm";
import DPagination from "../../components/DPagination/DPagination";
import api from "../../../services/api";

import { projectsStatus, projectsTypes } from "../../../enum/enums";

const Projects = () => {
    const projectFormRef = useRef();
    const [loading, setLoading] = useState(false);
    const [projects, setProjects] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);

    const [filterCustomer, setFilterCustomer] = useState(null);
    const [customersList, setCustomersList] = useState([]);
    const [filterProject, setFilterProject] = useState("");
    const [filterStatus, setFilterStatus] = useState(null);

    useEffect(() => {
        getCustomers();
    }, [])

    async function getCustomers() {
        await api.get("/customers?offset=1&limit=100")
            .then(response => {
                if (response.status === 200) {
                    const customersReturned = response.data.customers.map(customerItem => {
                        return {
                            value: customerItem._id,
                            label: customerItem.name
                        }
                    });

                    setCustomersList(customersReturned);
                }
            })
    }

    async function getProjects(page = currentPage) {
        setLoading(true);

        let filter = "&";
        if (filterCustomer) {
            filter += `customer=${filterCustomer.value}&`;
        }
        if (filterProject) {
            filter += `description=${filterProject}`;
        }
        if (filterStatus) {
            filter += `status=${filterStatus.value}`;
        }

        await api.get(`/projects?offset=${page}&limit=10${filter}`)
            .then(response => {
                if (response.status === 200) {
                    const projectsReturned = response.data.projects.map(projectInfo => {
                        const statusDescription = projectsStatus.find(x => x.value === projectInfo.status);
                        const typeDescription = projectsTypes.find(x => x.value === projectInfo.project_type);

                        return {
                            ...projectInfo,
                            statusDescription: statusDescription.label,
                            statusColor: statusDescription.color,
                            typeDescription: typeDescription.label
                        };
                    })

                    setProjects(projectsReturned);

                    const pages = Math.ceil(projectsReturned.length / 10);
                    setTotalPages(Math.max(pages, 1));
                }
            })

        setLoading(false);
    }

    function handleFilter() {
        setCurrentPage(1);
        getProjects(1);
    }

    async function handleDeleteProject(id) {
        swal({
            title: "Confirma a exclusão do projeto?",
            icon: "warning",
            buttons: ["Cancelar", "Confirmar"],
            dangerMode: true
        }).then(async (willDelete) => {
            if (willDelete) {
                setLoading(true);

                try {
                    await api.delete(`/projects/${id}`)
                .then(response => {
                    if (response.status === 200) {
                        getProjects();
                        notifySuccess();
                    }
                })
                } catch (err) {
                    notifyError();
                }

                setLoading(false);
            } else {
                return
            }
        })
    }

    function setActivePage(page) {
        setCurrentPage(page);
        getProjects(page);
    }

    function onCloseModal() {
        getProjects();
    }

    function notifySuccess() {
        toast.success(`✔️ Usuário excluído com sucesso!`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    function notifyError() {
        toast.error(`❌ Ocorreu um problema ao excluir o projeto`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    return (
        <>
            <Fragment>
                <PageTitle activeMenu="Table" motherMenu="Bootstrap" />
                <Row>
                    <Col lg={12}>
                        <Card>
                            <Card.Header>
                                <Card.Title>Projetos</Card.Title>
                                <div>
                                    <button
                                        className="btn btn-primary ms-1"
                                        onClick={() => projectFormRef.current.openModal()}
                                        title="Incluir Projeto"
                                    >
                                        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M12 3C7.05 3 3 7.05 3 12C3 16.95 7.05 21 12 21C16.95 21 21 16.95 21 12C21 7.05 16.95 3 12 3ZM12 19.125C8.1 19.125 4.875 15.9 4.875 12C4.875 8.1 8.1 4.875 12 4.875C15.9 4.875 19.125 8.1 19.125 12C19.125 15.9 15.9 19.125 12 19.125Z" fill="#FCFCFC" />
                                            <path d="M16.3498 11.0251H12.9748V7.65009C12.9748 7.12509 12.5248 6.67509 11.9998 6.67509C11.4748 6.67509 11.0248 7.12509 11.0248 7.65009V11.0251H7.6498C7.1248 11.0251 6.6748 11.4751 6.6748 12.0001C6.6748 12.5251 7.1248 12.9751 7.6498 12.9751H11.0248V16.3501C11.0248 16.8751 11.4748 17.3251 11.9998 17.3251C12.5248 17.3251 12.9748 16.8751 12.9748 16.3501V12.9751H16.3498C16.8748 12.9751 17.3248 12.5251 17.3248 12.0001C17.3248 11.4751 16.8748 11.0251 16.3498 11.0251Z" fill="#FCFCFC" />
                                        </svg> Novo
                                    </button>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <div className="basic-form">
                                    <div className="row">
                                        <div className="form-group mb-3 col-md-4">
                                            <label>Cliente</label>
                                            <Select
                                                id="filterCustomer"
                                                isClearable
                                                defaultValue={filterCustomer}
                                                value={filterCustomer}
                                                onChange={e => setFilterCustomer(e)}
                                                options={customersList}
                                                styles={{
                                                    control: (baseStyles, state) => ({
                                                        ...baseStyles,
                                                        height: 45,
                                                        backgroundColor: state.isDisabled ? "#dde0e3" : ""
                                                    }),
                                                }}
                                                placeholder="Selecione..."
                                                onBlur={handleFilter}
                                            />
                                        </div>
                                        <div className="form-group mb-3 col-md-4">
                                            <label>Projeto</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={filterProject}
                                                onChange={e => setFilterProject(e.target.value)}
                                                onBlur={handleFilter}
                                            />
                                        </div>
                                        <div className="form-group mb-3 col-md-4">
                                            <label>Status</label>
                                            <Select
                                                id="filterStatus"
                                                isClearable
                                                defaultValue={filterStatus}
                                                value={filterStatus}
                                                onChange={e => setFilterStatus(e)}
                                                options={projectsStatus}
                                                styles={{
                                                    control: (baseStyles, state) => ({
                                                        ...baseStyles,
                                                        height: 45,
                                                        backgroundColor: state.isDisabled ? "#dde0e3" : ""
                                                    }),
                                                }}
                                                placeholder="Selecione..."
                                                onBlur={handleFilter}
                                            />
                                        </div>
                                    </div>
                                    {/* <div className="row" style={{ textAlign: "right" }}>
                                        <div className="form-group mb-12 col-md-12">
                                            <Button
                                                variant="outline-primary btn-rounded"
                                                onClick={handleFilter}
                                            >
                                                Filtrar
                                            </Button>
                                        </div>
                                    </div> */}
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12}>
                        <Card>
                            <Card.Body>
                                <Table responsive striped bordered className="verticle-middle">
                                    <thead>
                                        <tr>
                                            <th scope="col">Cliente</th>
                                            <th scope="col">Projeto</th>
                                            <th scope="col">Tipo</th>
                                            {/* <th scope="col">Progresso</th> */}
                                            <th scope="col">Dt Início</th>
                                            <th scope="col">Dt Estimada</th>
                                            <th scope="col">Dt Entrega</th>
                                            <th scope="col">Status</th>
                                            <th scope="col"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            projects.map(projectInfo => {
                                                return (
                                                    <tr key={projectInfo._id}>
                                                        <td>{projectInfo.customer_name}</td>
                                                        <td>{projectInfo.description}</td>
                                                        <td>{projectInfo.typeDescription}</td>
                                                        {/* <td>
                                                            <ProgressBar now={70} variant="success" label={`${70}%`} />
                                                        </td> */}
                                                        <td>{dayjs(projectInfo.start_date).format("DD/MM/YYYY")}</td>
                                                        <td>{projectInfo.estimated_date ? dayjs(projectInfo.estimated_date).format("DD/MM/YYYY") : ""}</td>
                                                        <td>{projectInfo.end_date ? dayjs(projectInfo.end_date).format("DD/MM/YYYY") : ""}</td>
                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                <i className={`fa fa-circle ${projectInfo.statusColor} me-1`}></i>{" "}
                                                                {projectInfo.statusDescription}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <span className="d-flex justify-content-end">
                                                                <button
                                                                    className="btn btn-primary shadow sharp ms-1"
                                                                    title="Editar projeto"
                                                                    onClick={() => projectFormRef.current.openModal("update", projectInfo)}
                                                                >
                                                                    <i className="fas fa-pencil-alt"></i>
                                                                </button>
                                                                {
                                                                    projectInfo.status === "pending" ? (
                                                                        <button
                                                                            className="btn btn-danger shadow sharp ms-1"
                                                                            title="Excluir projeto"
                                                                            onClick={() => handleDeleteProject(projectInfo._id)}
                                                                        >
                                                                            <i className="fa fa-trash"></i>
                                                                        </button>
                                                                    ) : ""
                                                                }
                                                            </span>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </Table>

                                <DPagination
                                    activePage={currentPage}
                                    pages={totalPages}
                                    onActivePageChange={(i) => setActivePage(i)}
                                />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Fragment>

            <ProjectForm ref={projectFormRef} onClose={onCloseModal} />

            <ToastContainer />

            <ModalLoading
                visible={loading}
                message="Carregando Projetos..."
                onClose={setLoading}
            />
        </>
    );
};

export default Projects;
