import React, { forwardRef, useImperativeHandle, useState } from "react";
import {
    Button,
    Modal
} from "react-bootstrap";
import Select from "react-select";
import InputMask from "react-input-mask";

import DFormText from "../../components/DFormText/DFormText.js";

import { v4 as uuidv4 } from "uuid";

const ActivityForm = forwardRef((props, ref) => {
    const { onValidation, onClose, projects } = props;
    const [modalBox, setModalBox] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);

    const [projectsList, setProjectsList] = useState(projects);
    const [tasksList, setTasksList] = useState([]);

    const [activityId, setActivityId] = useState(undefined);
    const [project, setProject] = useState("");
    const [task, setTask] = useState("");
    const [activity, setActivity] = useState("");
    const [totalTime, setTotalTime] = useState("00:00");

    const [invalidProject, setInvalidProject] = useState(false);
    const [invalidTask, setInvalidTask] = useState(false);
    const [invalidActivity, setInvalidActivity] = useState(false);
    const [invalidTotalTime, setInvalidTotalTime] = useState(false);
    const [invalidTotalTimeMessage, setInvalidTotalTimeMessage] = useState("Informe o tempo da atividade");

    useImperativeHandle(ref, () => ({
        async openModal(mode, workOrderInfo = {}, projects) {
            setModalBox(true);
            setIsUpdate(false);

            setProjectsList(projects);

            if (mode === "update") {
                setIsUpdate(true);

                setActivityId(workOrderInfo.innerId);

                const projectSelected = projects.find(x => x.value === workOrderInfo.projectId);
                const projectTasks = projectSelected.tasks.map(taskItem => {
                    return {
                        value: taskItem._id,
                        label: taskItem.task,
                        status: taskItem.status
                    }
                });
                setTasksList(projectTasks);

                setProject(projectSelected);
                setTask({ value: workOrderInfo.taskId, label: workOrderInfo.taskDescription });
                setTotalTime(workOrderInfo.totalTime);
                setActivity(workOrderInfo.activity);
            }
        },
    }));

    async function handleSave(e) {
        e.preventDefault();

        setInvalidProject(false);
        setInvalidTask(false);
        setInvalidActivity(false);
        setInvalidTotalTime(false);

        let hasError = false;

        if (!project) {
            setInvalidProject(true);
            hasError = true;
        }

        if (!task) {
            setInvalidTask(true);
            hasError = true;
        }

        if (!totalTime || totalTime === "00:00") {
            setInvalidTotalTimeMessage("Informe o tempo da atividade");
            setInvalidTotalTime(true);
            hasError = true;
        } else {
            if (!onValidation(activityId, totalTime)) {
                setInvalidTotalTimeMessage("O tempo excede o total da OS");
                setInvalidTotalTime(true);
                hasError = true;
            }
        }

        if (!activity) {
            setInvalidActivity(true);
            hasError = true;
        }

        if (!hasError) {
            onClose({
                innerId: isUpdate ? activityId : uuidv4(),
                projectId: project.value,
                projectDescription: project.label,
                taskId: task.value,
                taskDescription: task.label,
                totalTime,
                activity
            });

            resetFields();
            setModalBox(false);
        }
    }

    function handleCancel() {
        resetFields();
        setModalBox(false);
    }

    function resetFields() {
        setProjectsList([]);
        setTasksList([]);

        setProject("");
        setTask("");
        setActivity("");
        setTotalTime("00:00");

        setInvalidProject(false);
        setInvalidTask(false);
        setInvalidActivity(false);
        setInvalidTotalTime(false);
        setInvalidTotalTimeMessage("Informe o tempo da atividade");
    }

    function handleChangeProject() {
        setTasksList([]);

        if (project) {
            const projectTasks = project.tasks.map(taskItem => {
                return {
                    value: taskItem._id,
                    label: taskItem.task,
                    status: taskItem.status
                }
            });
            setTasksList(projectTasks);
        }
    }

    return (
        <>
            <Modal size="xl" onHide={setModalBox} show={modalBox}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h3
                            className="modal-title"
                            id="exampleModalLabel"
                        >
                            {(!isUpdate ? "Incluir " : "Alterar ")}Atividade
                        </h3>
                        <button
                            type="button"
                            className="btn-close"
                            onClick={() => setModalBox(false)}
                        ></button>
                    </div>
                    <div className="modal-body">
                        <div className="basic-form">
                            <div className="row">
                                <div className="form-group mb-3 col-md-5">
                                    <label>Projeto</label>
                                    <Select
                                        id="project"
                                        autoFocus
                                        isClearable
                                        className={`${(invalidProject ? "form-control is-invalid" : "")}`}
                                        defaultValue={project}
                                        value={project}
                                        onChange={e => setProject(e)}
                                        options={projectsList}
                                        styles={{
                                            control: (baseStyles, state) => ({
                                                ...baseStyles,
                                                height: 45,
                                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                                            }),
                                        }}
                                        onBlur={handleChangeProject}
                                        placeholder="Selecione..."
                                    />
                                    <DFormText hidden={!invalidProject} color="danger">Informe o projeto</DFormText>
                                </div>
                                <div className="form-group mb-3 col-md-5">
                                    <label>Tarefa</label>
                                    <Select
                                        id="task"
                                        isClearable
                                        className={`${(invalidTask ? "form-control is-invalid" : "")}`}
                                        defaultValue={task}
                                        value={task}
                                        onChange={e => setTask(e)}
                                        options={tasksList}
                                        styles={{
                                            control: (baseStyles, state) => ({
                                                ...baseStyles,
                                                height: 45,
                                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                                            }),
                                        }}
                                        placeholder="Selecione..."
                                    />
                                    <DFormText hidden={!invalidTask} color="danger">Informe a tarefa</DFormText>
                                </div>
                                <div className="form-group mb-3 col-md-2">
                                    <label>Tempo</label>
                                    <InputMask
                                        mask="99:99"
                                        maskChar=" "
                                        value={totalTime}
                                        onChange={(event) => {
                                            setTotalTime(event?.target.value);
                                        }}
                                        alwaysShowMask={true}
                                    >
                                        {(inputProps) => <input {...inputProps} type="text" className={`text-end form-control ${(invalidTotalTime ? "is-invalid" : "")}`} />}
                                    </InputMask>
                                    <DFormText hidden={!invalidTotalTime} color="danger">{invalidTotalTimeMessage}</DFormText>
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group mb-3 col-md-12">
                                    <label>Atividades</label>
                                    <textarea
                                        type="text"
                                        className={`form-control ${(invalidActivity ? "is-invalid" : "")}`}
                                        value={activity}
                                        onChange={e => setActivity(e.target.value)}
                                        rows={10}
                                    />
                                    <DFormText hidden={!invalidActivity} color="danger">Informe a atividade realizada</DFormText>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <Button
                            variant="outline-danger btn-rounded"
                            onClick={handleCancel}
                        >
                            Cancelar
                        </Button>
                        <Button
                            variant="success btn-rounded"
                            onClick={handleSave}
                        >
                            {isUpdate ? "Atualizar" : "Incluir"}
                        </Button>
                    </div>
                </div>
            </Modal>
        </>
    );
});

export default ActivityForm;
