import React, { Fragment, useEffect, useRef, useState } from "react";
import PageTitle from "../../layouts/PageTitle";
import {
    Row,
    Col,
    Card,
    Table,
    Button,
} from "react-bootstrap";
import Select from "react-select";
import swal from "sweetalert";
import { ToastContainer, toast } from "react-toastify";
import dayjs from "dayjs";

import ModalLoading from "../../components/ModalLoading/ModalLoading";
import WorkOrdersForm from "./WorkOrdersForm";
import { workOrderStatusOptions } from "../../../enum/enums";

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ptBR from "date-fns/locale/pt-BR";

import api from "../../../services/api";
import DPagination from "../../components/DPagination/DPagination";

registerLocale("ptBR", ptBR);

const WorkOrders = () => {
    const workOrdersFormRef = useRef();
    const [loading, setLoading] = useState(false);

    const [customersList, setCustomersList] = useState([]);
    const [usersList, setUsersList] = useState([]);

    const [workOrders, setWorkOrders] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);

    const [filterDateFrom, setFilterDateFrom] = useState(null);
    const [filterDateTo, setFilterDateTo] = useState(null);
    const [filterCustomer, setFilterCustomer] = useState(null);
    const [filterUser, setFilterUser] = useState(null);
    const [filterStatus, setFilterStatus] = useState(null);

    useEffect(() => {
        getCustomers();
        getUsers();
    }, [])

    async function getCustomers() {
        await api.get("/customers?status=true&offset=1&limit=100")
            .then(response => {
                if (response.status === 200) {
                    const customersReturned = response.data.customers.map(customerItem => {
                        return {
                            value: customerItem._id,
                            label: customerItem.name
                        }
                    });

                    setCustomersList(customersReturned);
                }
            })
    }

    async function getUsers() {
        await api.get("/users?status=true&offset=1&limit=100")
            .then(response => {
                if (response.status === 200) {
                    const usersFiltered = response.data.users.filter(x => x.email !== "admin@dettalles.com.br");
                    const usersReturned = usersFiltered.map(userItem => {
                        return {
                            value: userItem._id,
                            label: userItem.name
                        }
                    });

                    setUsersList(usersReturned);
                }
            })
    }

    async function getWorkOrders(page = currentPage) {
        let filter = "";

        if (filterDateFrom && filterDateTo) {
            filter += `&startDate=${dayjs(filterDateFrom).toISOString()}&endDate=${dayjs(filterDateTo).toISOString()}`;
        }

        if (filterCustomer) {
            filter += `&customer=${filterCustomer.value}`;
        }

        if (filterUser) {
            filter += `&user=${filterUser.value}`;
        }

        if (filterStatus) {
            filter += `&status=${filterStatus.value}`;
        }

        await api.get(`/schedules?offset=${page}&limit=10${filter}`)
            .then(response => {
                if (response.status === 200) {
                    const workOrdersReturned = response.data.schedules.map(scheduleItem => {
                        const workOrderItem = scheduleItem.work_orders ?? undefined;

                        let workOrderStatus = "pending";
                        if (workOrderItem) {
                            workOrderStatus = workOrderItem.status;
                        }

                        const workOrderStatusInfo = workOrderStatusOptions.find(x => x.value === workOrderStatus);

                        return {
                            id: workOrderItem?._id ?? scheduleItem._id,
                            schedule_id: scheduleItem._id,
                            schedule_date: scheduleItem.schedule_date,
                            user_id: scheduleItem.user_id,
                            user_name: scheduleItem.user_name,
                            customer_id: scheduleItem.customer_id,
                            customer_name: scheduleItem.customer_name,
                            customer_user: workOrderItem?.customer_user ?? "",
                            start_time: workOrderItem?.start_time ?? "",
                            end_time: workOrderItem?.end_time ?? "",
                            trip_time: workOrderItem?.trip_time ?? "",
                            discount_time: workOrderItem?.discount_time ?? "",
                            activities: workOrderItem?.activities ?? [],
                            status: workOrderStatus,
                            status_description: workOrderStatusInfo.label,
                            status_color: workOrderStatusInfo.color
                        };
                    })

                    setWorkOrders(workOrdersReturned);

                    const pages = Math.ceil(response.data.total / 10);
                    setTotalPages(Math.max(pages, 1));
                }
            })
    }

    function handleFilter() {
        getWorkOrders(1);
        setCurrentPage(1);
    }

    function setActivePage(page) {
        setCurrentPage(page);
        getWorkOrders(page);
    }

    function onCloseModal() {
        getWorkOrders();
    }

    function handleDeleteWorkOrder(id) {
        swal({
            title: "Confirma a exclusão da OS?",
            icon: "warning",
            buttons: ["Cancelar", "Confirmar"],
            dangerMode: true
        }).then(async (willDelete) => {
            if (willDelete) {
                setLoading(true);

                try {
                    await api.delete(`/workorders/${id}`)
                .then(response => {
                    if (response.status === 200) {
                        notifySuccess();
                        getWorkOrders();
                    }
                })
                } catch (err) {
                    notifyError();
                }

                setLoading(false);
            } else {
                return
            }
        })
    }

    function notifySuccess() {
        toast.success(`✔️ OS excluída com sucesso!`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    function notifyError() {
        toast.error(`❌ Ocorreu um problema ao excluir a OS`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    return (
        <>
            <Fragment>
                <PageTitle activeMenu="Table" motherMenu="Bootstrap" />
                <Row>
                    <Col lg={12}>
                        <Card>
                            <Card.Header>
                                <Card.Title>Atendimentos</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <div className="basic-form">
                                    <div className="row">
                                        <div className="form-group mb-3 col-md-3">
                                            <label>Data Inicial</label>
                                            <br />
                                            <DatePicker
                                                selected={filterDateFrom}
                                                onChange={(date) => setFilterDateFrom(date)}
                                                locale="ptBR"
                                                dateFormat="P"
                                                className="form-control"
                                            />
                                        </div>
                                        <div className="form-group mb-3 col-md-3">
                                            <label>Data Final</label>
                                            <br />
                                            <DatePicker
                                                selected={filterDateTo}
                                                onChange={(date) => setFilterDateTo(date)}
                                                locale="ptBR"
                                                dateFormat="P"
                                                className="form-control"
                                            />
                                        </div>
                                        <div className="form-group mb-3 col-md-6">
                                            <label>Cliente</label>
                                            <Select
                                                id="filterCustomer"
                                                isClearable
                                                defaultValue={filterCustomer}
                                                value={filterCustomer}
                                                onChange={e => setFilterCustomer(e)}
                                                options={customersList}
                                                styles={{
                                                    control: (baseStyles, state) => ({
                                                        ...baseStyles,
                                                        height: 45,
                                                        backgroundColor: state.isDisabled ? "#dde0e3" : ""
                                                    }),
                                                }}
                                                placeholder="Selecione..."
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group mb-3 col-md-6">
                                            <label>Consultor</label>
                                            <Select
                                                id="filterUser"
                                                isClearable
                                                defaultValue={filterUser}
                                                value={filterUser}
                                                onChange={e => setFilterUser(e)}
                                                options={usersList}
                                                styles={{
                                                    control: (baseStyles, state) => ({
                                                        ...baseStyles,
                                                        height: 45,
                                                        backgroundColor: state.isDisabled ? "#dde0e3" : ""
                                                    }),
                                                }}
                                                placeholder="Selecione..."
                                            />
                                        </div>
                                        <div className="form-group mb-3 col-md-6">
                                            <label>Status</label>
                                            <Select
                                                id="filterStatus"
                                                isClearable
                                                defaultValue={filterStatus}
                                                value={filterStatus}
                                                onChange={e => setFilterStatus(e)}
                                                options={[
                                                    {
                                                        value: "pending",
                                                        label: "Pendente"
                                                    },
                                                    {
                                                        value: "sent",
                                                        label: "Emitida"
                                                    }
                                                ]}
                                                styles={{
                                                    control: (baseStyles, state) => ({
                                                        ...baseStyles,
                                                        height: 45,
                                                        backgroundColor: state.isDisabled ? "#dde0e3" : ""
                                                    }),
                                                }}
                                                placeholder="Selecione..."
                                            />
                                        </div>
                                    </div>
                                    <div className="row" style={{ textAlign: "right" }}>
                                        <div className="form-group mb-12 col-md-12">
                                            <Button
                                                variant="outline-primary btn-rounded"
                                                onClick={handleFilter}
                                            >
                                                Filtrar
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12}>
                        <Card>
                            <Card.Body>
                                <Table responsive>
                                    <thead>
                                        <tr key="osHeader">
                                            <th>
                                                <strong>Data</strong>
                                            </th>
                                            <th>
                                                <strong>Consultor</strong>
                                            </th>
                                            <th>
                                                <strong>Cliente</strong>
                                            </th>
                                            <th>
                                                <strong>Status</strong>
                                            </th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            workOrders.map(workOrderItem => {
                                                return (
                                                    <tr key={workOrderItem.id}>
                                                        <td>{dayjs(workOrderItem.schedule_date).format("DD/MM/YYYY")}</td>
                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                <span className="w-space-no">{workOrderItem.user_name}</span>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                <span className="w-space-no">{workOrderItem.customer_name}</span>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                <i className={`fa fa-circle ${workOrderItem.status_color} me-1`}></i>{" "}
                                                                {workOrderItem.status_description}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            {
                                                                workOrderItem.status === "pending" ? (
                                                                    <div className="d-flex">
                                                                        <button
                                                                            className="btn btn-success shadow sharp ms-1"
                                                                            title="Incluir OS"
                                                                            onClick={() => workOrdersFormRef.current.openModal("new", workOrderItem)}
                                                                        >
                                                                            <i className="fas fa-plus"></i>
                                                                        </button>
                                                                    </div>
                                                                ) : ""
                                                            }
                                                            {
                                                                (workOrderItem.status === "sent" || workOrderItem.status === "contested") ? (
                                                                    <div className="d-flex">
                                                                        <button
                                                                            className="btn btn-primary shadow sharp ms-1"
                                                                            title="Editar OS"
                                                                            onClick={() => workOrdersFormRef.current.openModal("update", workOrderItem)}
                                                                        >
                                                                            <i className="fas fa-pencil-alt"></i>
                                                                        </button>
                                                                        <button
                                                                            className="btn btn-danger shadow sharp ms-1"
                                                                            title="Excluir OS"
                                                                            onClick={() => handleDeleteWorkOrder(workOrderItem.id)}
                                                                        >
                                                                            <i className="fa fa-trash"></i>
                                                                        </button>
                                                                    </div>
                                                                ) : ""
                                                            }
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </Table>

                                <DPagination
                                    activePage={currentPage}
                                    pages={totalPages}
                                    onActivePageChange={(i) => setActivePage(i)}
                                />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Fragment>

            <WorkOrdersForm ref={workOrdersFormRef} onClose={onCloseModal} />

            <ToastContainer />

            <ModalLoading
                visible={loading}
                message="Carregando Atendimentos..."
                onClose={setLoading}
            />
        </>
    );
};

export default WorkOrders;
