import React, { Fragment, useEffect, useRef, useState } from "react";
import PageTitle from "../../layouts/PageTitle";
import {
    Row,
    Col,
    Card,
    Table
} from "react-bootstrap";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";

import CustomerForm from "./CustomerForm";
import DPagination from "../../components/DPagination/DPagination";
import ModalLoading from "../../components/ModalLoading/ModalLoading";
import { customerType } from "../../../enum/enums";

import api from "../../../services/api";
import swal from "sweetalert";

const Customers = () => {
    const customerFormRef = useRef();
    const [loading, setLoading] = useState(false);
    const [customers, setCustomers] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);

    const [hideFilter, setHideFilter] = useState(true);
    const [filterQs, setFilterQs] = useState("");
    const [filterType, setFilterType] = useState(null);
    const [filterStatus, setFilterStatus] = useState(null);

    const [errorMessage, setErrorMessage] = useState("Ocorreu um problema ao excluir o cliente");

    useEffect(() => {
        getCustomers(currentPage);
    }, [])

    async function getCustomers(page = currentPage) {
        let filter = "&";
        if (filterQs !== "") {
            filter += `qs=${filterQs}&`;
        }
        if (filterStatus) {
            filter += `status=${filterStatus.value}`;
        }
        if (filterType) {
            filter += `type=${filterType.value}`;
        }

        await api.get(`/customers?offset=${page}&limit=10${filter}`)
            .then(response => {
                if (response.status === 200) {
                    const customersReturned = response.data.customers.map(customerInfo => {
                        const customerTypeDescription = customerType.find(x => x.value === customerInfo.type);

                        return { ...customerInfo, customerTypeDescription };
                    })

                    setCustomers(customersReturned);

                    const pages = Math.ceil(customersReturned.length / 10);
                    setTotalPages(Math.max(pages, 1));
                }
            })
    }

    function handleFilter() {
        setCurrentPage(1);
        getCustomers(1);
    }

    function handleDeleteCustomer(customer) {
        swal({
            title: `Confirma a exclusão do ${customer.customerTypeDescription.label} ${customer.name}?`,
            icon: "warning",
            buttons: ["Fechar", "Confirmar"],
            dangerMode: true
        }).then(async (willDelete) => {
            if (willDelete) {
                try {
                    await api.delete(`/customers/${customer._id}`)
                        .then(response => {
                            if (response.status === 200) {
                                notifySuccess();
                                getCustomers();
                            }
                        })
                } catch (err) {
                    if (err.data.error === "IN_USE") {
                        setErrorMessage("O cliente já possui movimentos e não poderá ser excluído!");
                    }
                    notifyError();
                }
            } else {
                return
            }
        })
    }

    function notifySuccess() {
        toast.success(`✔️ Usuário excluído com sucesso!`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    function notifyError() {
        toast.error(`❌ ${errorMessage}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    function setActivePage(page) {
        setCurrentPage(page);

        getCustomers(page);
    }

    function onCloseModal() {
        getCustomers();
    }

    return (
        <>
            <Fragment>
                <PageTitle activeMenu="Table" motherMenu="Bootstrap" />
                <Row hidden={hideFilter}>
                    <Col lg={12}>
                        <Card>
                            <Card.Header>
                                <Card.Title>Filtros</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <div className="basic-form">
                                    <div className="row">
                                        <div className="form-group mb-3 col-md-12">
                                            <label>Informe parte do nome ou do CNPJ para pesquisa</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={filterQs}
                                                onChange={e => setFilterQs(e.target.value)}
                                                tabIndex="1"
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group mb-3 col-md-6">
                                            <label>Status</label>
                                            <Select
                                                id="filterStatus"
                                                isClearable
                                                defaultValue={filterStatus}
                                                value={filterStatus}
                                                onChange={e => setFilterStatus(e)}
                                                options={[
                                                    {
                                                        value: "true",
                                                        label: "Ativos"
                                                    },
                                                    {
                                                        value: "false",
                                                        label: "Inativos"
                                                    }
                                                ]}
                                                styles={{
                                                    control: (baseStyles, state) => ({
                                                        ...baseStyles,
                                                        height: 45,
                                                        backgroundColor: state.isDisabled ? "#dde0e3" : ""
                                                    }),
                                                }}
                                                placeholder="Selecione..."
                                            />
                                        </div>
                                        <div className="form-group mb-3 col-md-6">
                                            <label>Tipo</label>
                                            <Select
                                                id="filterType"
                                                isClearable
                                                defaultValue={filterType}
                                                value={filterType}
                                                onChange={e => setFilterType(e)}
                                                options={customerType}
                                                styles={{
                                                    control: (baseStyles, state) => ({
                                                        ...baseStyles,
                                                        height: 45,
                                                        backgroundColor: state.isDisabled ? "#dde0e3" : ""
                                                    }),
                                                }}
                                                placeholder="Selecione..."
                                            />
                                        </div>
                                    </div>

                                    <div className="row" style={{ textAlign: "right" }}>
                                        <div className="form-group mb-12 col-md-12">
                                            <button className="btn btn-primary ms-1" onClick={handleFilter}>
                                                Filtrar
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12}>
                        <Card>
                            <Card.Header>
                                <Card.Title>Clientes</Card.Title>
                                <div>
                                    <button
                                        className="btn btn-light ms-1"
                                        onClick={() => setHideFilter(!hideFilter)}
                                        title="Mostrar/esconder filtros"
                                    >
                                        <i className="fas fa-filter"></i>
                                    </button>
                                    <button
                                        className="btn btn-primary ms-1"
                                        onClick={() => customerFormRef.current.openModal()}
                                        title="Incluir Cliente"
                                    >
                                        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M12 3C7.05 3 3 7.05 3 12C3 16.95 7.05 21 12 21C16.95 21 21 16.95 21 12C21 7.05 16.95 3 12 3ZM12 19.125C8.1 19.125 4.875 15.9 4.875 12C4.875 8.1 8.1 4.875 12 4.875C15.9 4.875 19.125 8.1 19.125 12C19.125 15.9 15.9 19.125 12 19.125Z" fill="#FCFCFC" />
                                            <path d="M16.3498 11.0251H12.9748V7.65009C12.9748 7.12509 12.5248 6.67509 11.9998 6.67509C11.4748 6.67509 11.0248 7.12509 11.0248 7.65009V11.0251H7.6498C7.1248 11.0251 6.6748 11.4751 6.6748 12.0001C6.6748 12.5251 7.1248 12.9751 7.6498 12.9751H11.0248V16.3501C11.0248 16.8751 11.4748 17.3251 11.9998 17.3251C12.5248 17.3251 12.9748 16.8751 12.9748 16.3501V12.9751H16.3498C16.8748 12.9751 17.3248 12.5251 17.3248 12.0001C17.3248 11.4751 16.8748 11.0251 16.3498 11.0251Z" fill="#FCFCFC" />
                                        </svg> Novo
                                    </button>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <Table responsive>
                                    <thead>
                                        <tr>
                                            <th>
                                                <strong>CNPJ</strong>
                                            </th>
                                            <th>
                                                <strong>Nome</strong>
                                            </th>
                                            <th>
                                                <strong>Tipo</strong>
                                            </th>
                                            <th>
                                                <strong>Status</strong>
                                            </th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            customers.map(customer => {
                                                const documentFormatted = customer.document_id.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                                                    function (regex, arg1, arg2, arg3, arg4, arg5) {
                                                        return arg1 + '.' + arg2 + '.' + arg3 + '/' + arg4 + '-' + arg5;
                                                    });

                                                return (
                                                    <tr key={customer._id}>
                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                <span className="w-space-no">{documentFormatted}</span>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                <span className="w-space-no">{customer.name}</span>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                <i className={`fa fa-circle ${customer.type === "prospect" ? "text-warning" : "text-success"} me-1`}></i>{" "}
                                                                {customer.customerTypeDescription.label}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                <i className={`fa fa-circle ${customer.status ? "text-success" : "text-danger"} me-1`}></i>{" "}
                                                                {customer.status ? "Ativo" : "Inativo"}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="d-flex">
                                                                <button
                                                                    className="btn btn-primary shadow sharp ms-1"
                                                                    title="Editar cadastro"
                                                                    onClick={() => customerFormRef.current.openModal("update", customer)}
                                                                >
                                                                    <i className="fas fa-pencil-alt"></i>
                                                                </button>
                                                                <button
                                                                    className="btn btn-danger shadow sharp ms-1"
                                                                    title="Excluir cadastro"
                                                                    onClick={() => handleDeleteCustomer(customer)}
                                                                >
                                                                    <i className="fa fa-trash"></i>
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </Table>

                                <DPagination
                                    activePage={currentPage}
                                    pages={totalPages}
                                    onActivePageChange={(i) => setActivePage(i)}
                                />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Fragment>

            <CustomerForm ref={customerFormRef} onClose={onCloseModal} />

            <ToastContainer />

            <ModalLoading
                visible={loading}
                message="Carregando Clientes..."
                onClose={setLoading}
            />
        </>
    );
};

export default Customers;
