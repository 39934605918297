import {
    formatError,
    login,
    runLogoutTimer,
    saveTokenInStorage,
    signUp,
} from '../../services/AuthService';


export const SIGNUP_CONFIRMED_ACTION = '[signup action] confirmed signup';
export const SIGNUP_FAILED_ACTION = '[signup action] failed signup';
export const LOGIN_CONFIRMED_ACTION = '[login action] confirmed login';
export const LOGIN_FAILED_ACTION = '[login action] failed login';
export const LOADING_TOGGLE_ACTION = '[Loading action] toggle loading';
export const LOGOUT_ACTION = '[Logout action] logout action';

export function signupAction(email, password, history) {
    return (dispatch) => {
        signUp(email, password)
        .then((response) => {
            saveTokenInStorage(false, response.data);
            runLogoutTimer(
                dispatch,
                response.data.expiresIn * 1000,
                history,
            );
            dispatch(confirmedSignupAction(response.data));
            history.push('/dashboard');
        })
        .catch((error) => {
            const errorMessage = formatError(error.response.data);
            dispatch(signupFailedAction(errorMessage));
        });
    };
}

export function logout(history) {
    if (localStorage.getItem("xDetRem")) {
        localStorage.removeItem("xDetRem");
        localStorage.removeItem("xDetTk");
        localStorage.removeItem("xDetTkr");
        localStorage.removeItem("xDetUsr");
    } else {
        sessionStorage.removeItem("xDetTk");
        sessionStorage.removeItem("xDetTkr");
        sessionStorage.removeItem("xDetUsr");
    }

    history.push('/login');
    return {
        type: LOGOUT_ACTION,
    };
}

export function loginAction(email, password, remember, history) {
    return (dispatch) => {
        login(email, password)
            .then((response) => {
                saveTokenInStorage(remember, response.data);
                dispatch(loginConfirmedAction(response.data));

                const userInfo = response.data.user;
                (userInfo.profile === "admin" || userInfo.profile === "manager") ? history.push('/dashboard') : history.push('/schedule');
            })
            .catch(() => {
                dispatch(loginFailedAction("E-mail ou senha inválidos!"));
            });
    };
}

export function loginFailedAction(data) {
    return {
        type: LOGIN_FAILED_ACTION,
        payload: data,
    };
}

export function loginConfirmedAction(data) {
    return {
        type: LOGIN_CONFIRMED_ACTION,
        payload: data,
    };
}

export function confirmedSignupAction(payload) {
    return {
        type: SIGNUP_CONFIRMED_ACTION,
        payload,
    };
}

export function signupFailedAction(message) {
    return {
        type: SIGNUP_FAILED_ACTION,
        payload: message,
    };
}

export function loadingToggleAction(status) {
    return {
        type: LOADING_TOGGLE_ACTION,
        payload: status,
    };
}
