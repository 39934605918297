const customerType = [
    {
        value: "prospect",
        label: "Prospect"
    },
    {
        value: "customer",
        label: "Cliente"
    }
];

const profiles = [
    {
        value: "admin",
        label: "Administrador"
    },
    {
        value: "manager",
        label: "Coordenador"
    },
    {
        value: "consultant",
        label: "Consultor"
    }
];

const projectsPriceType = [
    {
        value: "hour",
        label: "Hora"
    },
    {
        value: "fixed",
        label: "Fechado"
    }
];

const projectsStatus = [
    {
        value: "pending",
        label: "Não iniciado",
        color: "text-warning"
    },
    {
        value: "doing",
        label: "Em andamento",
        color: "text-success"
    },
    {
        value: "late",
        label: "Atrasado",
        color: "text-danger"
    },
    {
        value: "done",
        label: "Finalizado",
        color: "text-primary"
    }
];

const projectsTypes = [
    {
        value: "open",
        label: "Horas Abertas"
    },
    {
        value: "month",
        label: "Horas Mensais"
    },
    {
        value: "close",
        label: "Projeto Fechado"
    }
];

const states = [
    {
        value: "AC",
        label: "Acre"
    },
    {
        value: "AL",
        label: "Alagoas"
    },
    {
        value: "AM",
        label: "Amazonas"
    },
    {
        value: "AP",
        label: "Amapá"
    },
    {
        value: "BA",
        label: "Bahia"
    },
    {
        value: "CE",
        label: "Ceará"
    },
    {
        value: "DF",
        label: "Distrito Federal"
    },
    {
        value: "ES",
        label: "Espírito Santo"
    },
    {
        value: "GO",
        label: "Goiás"
    },
    {
        value: "MA",
        label: "Maranhão"
    },
    {
        value: "MG",
        label: "Minas Gerais"
    },
    {
        value: "MS",
        label: "Mato Grosso do Sul"
    },
    {
        value: "MT",
        label: "Mato Grosso"
    },
    {
        value: "PA",
        label: "Pará"
    },
    {
        value: "PB",
        label: "Paraíba"
    },
    {
        value: "PE",
        label: "Pernambuco"
    },
    {
        value: "PI",
        label: "Piauí"
    },
    {
        value: "PR",
        label: "Paraná"
    },
    {
        value: "RJ",
        label: "Rio de Janeiro"
    },
    {
        value: "RN",
        label: "Rio Grande do Norte"
    },
    {
        value: "RO",
        label: "Rondônia"
    },
    {
        value: "RR",
        label: "Roraima"
    },
    {
        value: "RS",
        label: "Rio Grande do Sul"
    },
    {
        value: "SC",
        label: "Santa Catarina"
    },
    {
        value: "SE",
        label: "Sergipe"
    },
    {
        value: "SP",
        label: "São Paulo"
    },
    {
        value: "TO",
        label: "Tocantins"
    }
];

const workOrderStatusOptions = [
    {
        value: "pending",
        label: "Pendente",
        color: "text-warning"
    },
    {
        value: "sent",
        label: "Emitida",
        color: "text-success"
    },
    {
        value: "contested",
        label: "Contestada",
        color: "text-danger"
    },
    {
        value: "done",
        label: "Confirmada",
        color: "text-primary"
    }
];

const workPlaces = [
    {
        value: "office",
        label: "Escritório"
    },
    {
        value: "home",
        label: "Home"
    },
    {
        value: "inloco",
        label: "Cliente"
    }
];

const yesNoOptions = [
    {
        value: "YES",
        label: "Sim"
    },
    {
        value: "NO",
        label: "Não"
    }
];

export {
    customerType,
    profiles,
    projectsPriceType,
    projectsStatus,
    projectsTypes,
    states,
    workOrderStatusOptions,
    workPlaces,
    yesNoOptions
};