/// Menu
import Metismenu from "metismenujs";
import React, { Component, useContext, useEffect, useState } from "react";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
/// Link
import { Link } from "react-router-dom";

import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { ThemeContext } from "../../../context/ThemeContext";
import { hasAccess } from "../../../services/utils";
//import { add } from "date-fns";

//import profile from "../../../images/user.jpg";


class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new Metismenu(this.$el);
  }
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

const SideBar = () => {
  const {
    iconHover,
    sidebarposition,
    headerposition,
    sidebarLayout,
  } = useContext(ThemeContext);


  useEffect(() => {
    // var btn = document.querySelector(".nav-control");
    // var aaa = document.querySelector("#main-wrapper");
    // function toggleFunc() {
    //   return aaa.classList.toggle("menu-toggle");
    // }
    // btn.addEventListener("click", toggleFunc);


    //for sidebar overlay 

    // var btn = document.querySelector(".nav-control");
    // var mainwrapper = document.querySelector("#main-wrapper");
    // var sidebarclose = document.querySelector(".sidebar-close");
    // function toggleFunc() {
    //   return mainwrapper.classList.toggle("menu-toggle"), sidebarclose.classList.toggle("menu-toggle");
    // }
    // btn.addEventListener("click", toggleFunc);

    // function CloseFunc() {
    //   return mainwrapper.classList.remove("menu-toggle"), sidebarclose.classList.toggle("menu-toggle");
    // }

    // sidebarclose.addEventListener("click", CloseFunc);


    //sidebar icon Heart blast
    //var handleheartBlast = document.querySelector('.heart');


  }, []);
  //For scroll
  const [hideOnScroll, setHideOnScroll] = useState(true)
  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y > prevPos.y
      if (isShow !== hideOnScroll) setHideOnScroll(isShow)
    },
    [hideOnScroll]
  )
  /// Path
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];
  /// Active menu
  let deshBoard = [
    "dashboard",
    "dashboard-dark",
    "banking",
    "ticketing",
    "crypto",
    "invoice",
    "contact",
    "kanban",
    //"task",
  ],
    records = [
      "users",
      "customers"
    ],
    projects = [
      "projects",
      "schedule",
      "orders"
    ],
    file = [
      "file-manager",
      "user",
      "calendar",
      "to-do-list",
      "chat",
      "activity",
    ],
    app = [
      "app-profile",
      "post-details",
      "app-calender",
      "email-compose",
      "email-inbox",
      "email-read",
      "ecom-product-grid",
      "ecom-product-list",
      "ecom-product-order",
      "ecom-checkout",
      "ecom-invoice",
      "ecom-customers",
      "post-details",
      "ecom-product-detail",
    ],
    email = ["email-compose", "email-inbox", "email-read"],
    shop = [
      "ecom-product-grid",
      "ecom-product-list",
      "ecom-product-list",
      "ecom-product-order",
      "ecom-checkout",
      "ecom-invoice",
      "ecom-customers",
      "ecom-product-detail",
    ],
    charts = [
      "chart-rechart",
      "chart-flot",
      "chart-chartjs",
      "chart-chartist",
      "chart-sparkline",
      "chart-apexchart",
    ],
    bootstrap = [
      "ui-accordion",
      "ui-badge",
      "ui-alert",
      "ui-button",
      "ui-modal",
      "ui-button-group",
      "ui-list-group",
      "ui-card",
      "ui-carousel",
      "ui-dropdown",
      "ui-popover",
      "ui-progressbar",
      "ui-tab",
      "ui-typography",
      "ui-pagination",
      "ui-grid",
    ],
    plugins = [
      "uc-select2",
      "uc-nestable",
      "uc-sweetalert",
      "uc-toastr",
      "uc-noui-slider",
      "map-jqvmap",
      "uc-lightgallery",
    ],

    widget = ["widget-basic"],
    forms = [
      "form-element",
      "form-wizard",
      "form-ckeditor",
      "form-pickers",
      "form-validation",
    ],
    table = [
      "datatable-filtering",
      "datatable-sorting",
      "table-bootstrap-basic",
      //"table-datatable-basic"
    ],
    pages = [
      "page-register",
      "page-login",
      "page-lock-screen",
      "page-error-400",
      "page-error-403",
      "page-error-404",
      "page-error-500",
      "page-error-503",
    ],
    error = [
      "page-error-400",
      "page-error-403",
      "page-error-404",
      "page-error-500",
      "page-error-503",
    ];
  return (
    <div
      className={`dlabnav ${iconHover} ${sidebarposition.value === "fixed" &&
        sidebarLayout.value === "horizontal" &&
        headerposition.value === "static"
        ? hideOnScroll > 120
          ? "fixed"
          : ""
        : ""
        }`}
    >
      <PerfectScrollbar className="dlabnav-scroll">
        <MM className="metismenu" id="menu">
          <li className={`${deshBoard.includes(path) ? "mm-active" : ""}`} hidden={!hasAccess()}>
            <Link className="has-arrow" to="#" >
              <i className="material-icons-outlined">dashboard</i>
              <span className="nav-text">Dashboard</span>
            </Link>
            <ul>
              <li><Link className={`${path === "dashboard" ? "mm-active" : ""}`} to="/dashboard"> Dashboard</Link></li>
            </ul>
          </li>
          <li className={`${records.includes(path) ? "mm-active" : ""}`} hidden={!hasAccess()}>
            <Link className="has-arrow ai-icon" to="#" >
              <i className="material-icons">folder</i>
              <span className="nav-text">Cadastros</span>
            </Link>
            <ul>
              <li><Link className={`${path === "customers" ? "mm-active" : ""}`} to="/customers">Clientes</Link></li>
              <li><Link className={`${path === "users" ? "mm-active" : ""}`} to="/users">Usuários</Link></li>
            </ul>
          </li>
          <li className={`${projects.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow ai-icon" to="#" >
              <i className="material-icons"> assessment </i>
              <span className="nav-text">Projetos</span>
            </Link>
            <ul >
              <li>
                <Link
                  className={`${path === "projects" ? "mm-active" : ""}`}
                  to="/projects"
                  hidden={!hasAccess()}
                >
                  Projetos
                </Link>
              </li>
              <li><Link className={`${path === "schedule" ? "mm-active" : ""}`} to="/schedule">Agenda</Link></li>
              <li><Link className={`${path === "orders" ? "mm-active" : ""}`} to="/orders">Atendimento</Link></li>
            </ul>
          </li>
        </MM>
      </PerfectScrollbar>
    </div>
  );
};

export default SideBar;
